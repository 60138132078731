import styled from "@emotion/styled";
import React from "react";
import { Icon } from "semantic-ui-react";

export const MessageInfoBar = styled(({backgroundColor, ...others})=><div {...others}/>)`
    text-align: center;
    font-size: 16px;
    padding: 10px 0 10px 0;
    background-color: ${props=>props.backgroundColor};
    color: white;
`
export const MessageInfo = styled(({textColor, ...others})=><div {...others} />)`
    color: ${props=>props.textColor};
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    & > p a{
        color: ${props=>props.textColor};
        text-decoration: underline;
    }
`
export const DismissIcon = styled(Icon)`
    cursor: pointer;
    float: right;
    right: 10px;
    position: relative;
    top: 4px;
`
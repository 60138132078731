import Axios from 'axios'
import {sendEmail} from '../utils/ApiUrls'

const send = async (apiUrl:string,name:string, phone:string, subject:string, message:string, from:string) =>{
    const url = sendEmail(apiUrl);
    const result = await Axios.post(url, {
        Name: name,
        EmailAddressFrom: from,
        Phone: phone,
        Subject: subject,
        Message: message
    });
    
    return result.data.Data;
}

export const EmailService = {
    send: send
}
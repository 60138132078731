import { NavItem } from '@components/Layout/mapper';
import React from 'react'
import {
    NavMenuLinkDropdown,
    NavMenuLinkDropdownMenu,
    NavMenuLinkDropdownItemLink,
    DropdownListItemTitle,
    NavMenuLinkDropdownText,
    DropdownItem,
    NavListItem
}from './NavigationMenuDropdown.styles'


const userTrigger = (text:string) => (
      <NavMenuLinkDropdownText>{text}</NavMenuLinkDropdownText>
  );

interface NavigationMenuDropdownProps{
  text:string;
  linkList: {
    title: string,
    url: string
  }[]
}  

const NavigationMenuDropdown = ({text, linkList}:NavigationMenuDropdownProps) =>{
    const trigger = userTrigger(text);
    return (
      <NavListItem>
      <NavMenuLinkDropdown trigger={trigger} icon='angle down' simple>
        <NavMenuLinkDropdownMenu>
            {
                linkList.map((linkItem,idx)=>{
                    const {title,url} = linkItem;
                    return <DropdownItem key={idx}>
                      <NavMenuLinkDropdownItemLink url={url}>
                        <DropdownListItemTitle>{title}</DropdownListItemTitle>
                      </NavMenuLinkDropdownItemLink>
                      </DropdownItem>
                })
            }  
        </NavMenuLinkDropdownMenu>
      </NavMenuLinkDropdown>
    </NavListItem>
    );
}

export default NavigationMenuDropdown;
import { KpFormikFieldProps } from "./types";
import React from "react";
import { KpFormField, KpFormInputErrorMessage } from "@styles/Global.forms";

var get = require('lodash/get');

export const KpInputFormikField = (props: KpFormikFieldProps) =>{
    const {data, errors,touched,handleChange,handleBlur,values,status} = props;
    return (
        <KpFormField required={data.isRequired} error={get(errors,data.id) && get(touched, data.id)}>
        {
            data.label && 
            <label>{data.label}</label>
        }
        {
        get(errors,data.id) && get(touched, data.id) && (
        <KpFormInputErrorMessage>{get(errors, data.id)}</KpFormInputErrorMessage>
        )}
        <input
            id={data.id}
            name={data.name}
            type={data.type}
            data-private="lipsum"
            aria-label={data.name}
            disabled={status.isSubmitting}
            placeholder={data.placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
            value={get(values, data.id)} />
    </KpFormField>
    );
}

import styled from "@emotion/styled";
import { Icon, Modal } from "semantic-ui-react";
import { Link } from "gatsby";

export const PromotionPopupModalLink = styled(Link)`
    line-height: 0 !important;
    display: block;
`

export const PromotionPopupModalBackgroundImage = styled.img`
    width: 100%;
    border-radius: 0.28571429rem;
`

export const PromotionPopupModalCloseIcon = styled(Icon)`
    position: absolute;
    top: 12px;
    right: 10px;
    color: white;
    cursor: pointer;
`

export const PromotionPopupModal = styled(Modal)`
    width: auto !important;
    max-width: 600px;
    max-height: 600px;
    @media (max-width: 600px) {
        max-width: 90vw;
        max-height: 90vw;
    }
    position: relative;
`
export const PromotionPopupFormModal = styled(Modal)`
    max-width: 920px;
    max-height: 560px;
    border-radius: 0 !important;
    @media (max-width: 600px) {
        max-width: 90vw;
        max-height: 90vw;
    }
    position: relative;

    > a > img {
        width: 50%;
        max-width: 460px;
    }

    > i {
        position: absolute;
        top: 12px;
        right: 10px;
        color: white;
        cursor: pointer;
    }
`
export const CompetitionPromotionForm = styled.div`
    max-width: 460px;
    padding: 55px 45px;
`
export const CompetitionPromotionFormContent = styled(Modal.Content)`
    padding: 0 !important;
`
export const ModalCloseButton = styled(Icon)`
    float: right;
    cursor: pointer;
    margin-right: 20px !important;
    margin-top: 20px !important;
`
export const ModalCloseButtonMobile = styled(Icon)`
    float: right;
    padding: 0 20px;
`
import React, { Fragment } from "react"
import { Link, navigate } from "gatsby"
import {
  MobileHeaderSegment,
  MobileHeaderLogo,
  SidebarIcon,
  SimpleMobileHeaderSegment,
  ShoppingCartWrapper,
  PhoneLinkWrapper,
  PhoneLink,
  MobileNavWrapper,
  DarkMobileHeaderSegment,
} from "./Header.styles"

import { ShoppingCartContainer } from "@elements/index"
import { ProgressStatusBar } from "@components/ProgressStatusBar"
import { PhoneNavigation } from "@assets/svgs"
import { connect } from "react-redux"
import { Record } from "immutable"
import { currentBasketSelector } from "@redux/selectors/basketSelector"
import { Dispatch } from "redux"
import UserLink from "@elements/UserLink/UserLink"
import { isMarketplacePages } from "./DesktopPageHeader"
import theme from "@styles/theme"
import { getCurrentCheckout, getEmptyCheckout } from "@redux/localStorage/checkout"
import { Icon } from "semantic-ui-react"
import { Address, Checkout } from "@models/Checkout/Checkout"
import { updateDealerAddress, updateDeliveryAddress, updateIsAcceptStatement, updateIsDealerOrder, updatePersonalDetails } from "@redux/actions/checkout"

interface MobilePageHeaderProps {
  apiUrl: string
  handleToggleSidebar: any
  currentStep?: number
  sidebarOpened: boolean
  version: string
  cartLineItems: []
  updatePersonalDetails: (personalDetails: Checkout) => void
  updateDeliveryAddress: (deliveryAddress: Address) => void
  updateDealerAddress: (dealerAddress: Address) => void
  updateIsDealerOrder: (isDealerOrder: boolean) => void
  updateIsAcceptStatement: (isAcceptStatement: boolean) => void
}

class MobilePageHeaderContainer extends React.Component<MobilePageHeaderProps> {
  constructor(props: MobilePageHeaderProps) {
    super(props)
  }
  componentDidMount() {
    if (
      this.props.version === "simple" &&
      this.props.currentStep &&
      this.props.currentStep > 2 &&
      this.props.currentStep <= 4 &&
      this.props.cartLineItems.length < 1
    ) {
      navigate("/shopping-cart/")
    }
  }
  
  render() {
    const { apiUrl, handleToggleSidebar, currentStep, version } = this.props
    const isSimple = version !== "standard"
    const isMarketplacePage = isMarketplacePages()
    const checkout = getCurrentCheckout()
    
    const signOut = () => {   
      if(checkout.isDealerOrder) {
        const emptyCheckout = getEmptyCheckout()
        this.props.updatePersonalDetails(emptyCheckout)
        this.props.updateIsDealerOrder(false)
        this.props.updateDealerAddress(emptyCheckout.dealerAddress)
        this.props.updateDeliveryAddress(emptyCheckout.deliveryAddress)
        this.props.updateIsAcceptStatement(emptyCheckout.isAcceptStatement)
        navigate("/dealer-login")
      } 
    }

    return (
      <MobilePageHeaderWrapper currentStep={currentStep} version={version}>
        <div>
          <SidebarIcon
            name="sidebar"
            size="big"
            onClick={handleToggleSidebar}
          />
          <Link to={checkout.isDealerOrder ? "/dealer-new-order" : "/"} aria-label="Homepage">
            <MobileHeaderLogo width={115} height={34} reverse={true}/>
          </Link>
          <MobileNavWrapper>
            {version === "simple" ? ( 
              checkout.isDealerOrder ? 
              <PhoneLinkWrapper onClick={signOut}>
                <Icon name="sign-out" size="huge" />
              </PhoneLinkWrapper>
              :
              <PhoneLinkWrapper>
                <PhoneLink href="tel:0800650111">
                  <PhoneNavigation />
                </PhoneLink>
              </PhoneLinkWrapper>
            ) : (
                isMarketplacePage && version != "dealer" && <UserLink apiUrl={apiUrl} />
              )}

            {!this.props.sidebarOpened && (
              <ShoppingCartContainer isSimple={isSimple} apiUrl={apiUrl} fill={theme.brand.colors.white}/>
            )}
          </MobileNavWrapper>
        </div>
      </MobilePageHeaderWrapper>
    )
  }
}

const MobilePageHeaderWrapper = ({
  version,
  currentStep,
  children,
}: {
  version: string
  currentStep?: number
  children: any
}) => {

  return (
    <Fragment>
      {version === "standard" ? (
        <MobileHeaderSegment basic>{children}</MobileHeaderSegment>
      ) : ( (version === "dark") ? 
        (<DarkMobileHeaderSegment basic>{children}</DarkMobileHeaderSegment>) : (
          <Fragment>
            <SimpleMobileHeaderSegment basic>
              {children}
            </SimpleMobileHeaderSegment>
            {currentStep !== undefined && currentStep >= 3 &&(
              <ProgressStatusBar isMobile={true} currentStep={currentStep} />
            )}
          </Fragment>
        ))}
    </Fragment>
  )
}
const mapStateToProps = (state: Record<any>) => {
  const cart = currentBasketSelector(state).toJS()
  return {
    cartLineItems: cart.basketLines,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updatePersonalDetails(personalDetails: Checkout) {    
    dispatch(updatePersonalDetails(personalDetails))
  },
  updateIsDealerOrder(isDealerOrder: boolean){
    dispatch(updateIsDealerOrder(isDealerOrder))
  },
  updateDeliveryAddress(deliveryAddress: Address) {
    dispatch(updateDeliveryAddress(deliveryAddress));
  },
  updateDealerAddress(dealerAddress: Address){
    dispatch(updateDealerAddress(dealerAddress))
  },
  updateIsAcceptStatement(isAcceptStatement: boolean) {
    dispatch(updateIsAcceptStatement(isAcceptStatement));
  }
})
const MobilePageHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobilePageHeaderContainer)
export default MobilePageHeader

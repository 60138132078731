import React, { FunctionComponent } from 'react'
interface Props{
  fill?: string,
  width?: number
  height?: number
}

const Email: FunctionComponent<Props> = (props) =>{
  const width = '' + props.width;
  const height = '' + props.height;
    return (
<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 31 21">
  <g transform="translate(0.5 0.5)">
    <g >
      <path d="M26.875,20H3.125A3.1,3.1,0,0,1,0,16.875V3.125A3.1,3.1,0,0,1,3.125,0h23.75A3.1,3.1,0,0,1,30,3.125v13.75A3.1,3.1,0,0,1,26.875,20ZM3.125,1.25A1.922,1.922,0,0,0,1.25,3.125v13.75A1.922,1.922,0,0,0,3.125,18.75h23.75a1.922,1.922,0,0,0,1.875-1.875V3.125A1.922,1.922,0,0,0,26.875,1.25Z" fill={props.fill} stroke={props.fill} stroke-width="1"/>
    </g>
    <g transform="translate(2.925 2.739)">
      <path d="M14.415,10.7a3.777,3.777,0,0,1-2-.625l-9.75-6.75c-.375-.125-.375-.5-.25-.875a.618.618,0,0,1,.875-.125l9.875,6.75a2.271,2.271,0,0,0,2.5,0l9.75-6.625a.625.625,0,1,1,.75,1l-9.75,6.625A2.941,2.941,0,0,1,14.415,10.7Z" transform="translate(-2.34 -2.191)" fill={props.fill} stroke={props.fill} strokeWidth="1"/>
    </g>
    <g  transform="translate(2.989 10.418)">
      <path d="M3.027,15.041a.948.948,0,0,1-.5-.25.618.618,0,0,1,.125-.875l6.625-5.5a.6.6,0,0,1,.75.125.618.618,0,0,1-.125.875l-6.625,5.5C3.277,15.041,3.152,15.041,3.027,15.041Z" transform="translate(-2.391 -8.334)" fill={props.fill} stroke={props.fill} strokeWidth="1"/>
    </g>
    <g transform="translate(19.293 10.418)">
      <path d="M22.516,15.041a.459.459,0,0,1-.375-.125l-6.5-5.5a.77.77,0,0,1-.125-.875.77.77,0,0,1,.875-.125l6.5,5.5a.77.77,0,0,1,.125.875C22.891,15.041,22.766,15.041,22.516,15.041Z" transform="translate(-15.434 -8.334)" fill={props.fill} stroke={props.fill} strokeWidth="1"/>
    </g>
  </g>
</svg>

    )
}

Email.defaultProps = {
  fill: "#3dae2b",
  width: 31,
  height: 21
}
export default Email
import { EmailRegex } from "@utils/Constant";
import { FormFieldData } from "./types";


const firstname_simplify:FormFieldData = {
    id: "firstname",
    name: "firstname",
    tag: "input",
    initialValue: "",
    label: "",
    type: "text",
    placeholder: "First Name",
    isRequired: true,
    requiredErrorMessage: "First name is required.",
    width:{
        mobile: 16,
        tablet: 16,
        computer: 16
    }
}
const lastname_simplify: FormFieldData = {
    id: "lastname",
    name: "lastname",
    tag: "input",
    initialValue: "",
    label: "",
    type: "text",
    placeholder: "Last Name",
    isRequired: true,
    requiredErrorMessage: "Last name is required.",
    width:{
        mobile: 16,
        tablet: 16,
        computer: 16
    }
}

const email_simplify: FormFieldData =  {
    id: "email",
    name: "email",
    tag: "input",
    initialValue: "",
    label: "",
    type: "text",
    placeholder: "Email",
    isRequired: true,
    requiredErrorMessage: "Email is required.",
    regExpValidation: {
        regex: EmailRegex,
        errorMessage: "Must enter a valid email address"
    },
    width:{
        mobile: 16,
        tablet: 16,
        computer: 16
    }
}


const firstname:FormFieldData = {
    id: "firstname",
    name: "firstname",
    tag: "input",
    initialValue: "",
    label: "First Name",
    type: "text",
    placeholder: "First Name",
    isRequired: true,
    requiredErrorMessage: "First name is required.",
    width:{
        mobile: 16,
        tablet: 16,
        computer: 8
    }
}
const lastname: FormFieldData = {
    id: "lastname",
    name: "lastname",
    tag: "input",
    initialValue: "",
    label: "Last Name",
    type: "text",
    placeholder: "Last Name",
    isRequired: true,
    requiredErrorMessage: "Last name is required.",
    width:{
        mobile: 16,
        tablet: 16,
        computer: 8
    }
}

const email: FormFieldData =  {
    id: "email",
    name: "email",
    tag: "input",
    initialValue: "",
    label: "Email",
    type: "text",
    placeholder: "Email",
    isRequired: true,
    requiredErrorMessage: "Email is required.",
    regExpValidation: {
        regex: EmailRegex,
        errorMessage: "Must enter a valid email address"
    },
    width:{
        mobile: 16,
        tablet: 16,
        computer: 16
    }
}

const termsConditions: FormFieldData = {
    id: "terms_conditions",
    name: "terms_conditions",
    tag: "checkbox",
    initialValue: [],
    isRequired: true,
    requiredErrorMessage: "Accept Terms & Conditions is required.",
    width: {
        computer: 16,
        tablet: 16,
        mobile: 16
    },
    checkboxLabelHtml: ""
}

export const predefinedFields = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        firstname_simplify: firstname_simplify,
        lastname_simplify: lastname_simplify,
        email_simplify: email_simplify,
        termsConditions: termsConditions
}

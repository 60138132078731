interface FooterLinkListProp{
    title:{
        value:string
    }
    links: {
        linked_items: Array<{
            elements:{
                url: {
                    value: string
                } | undefined
                general_page_content__title:{
                    value: string
                }
                page_info___checkout__title:{
                    value: string
                } | undefined
            }
        }>
    }
}
export const mapToFooterLinks = (linkList: {linked_items: Array<{elements:FooterLinkListProp}>}) =>{
    const result = linkList.linked_items.map(list=>mapToLinkList(list.elements));
    return {
        first: result[0],
        second: result[1],
        third: result[2]
    }
}

interface FooterLinkItem{
    title: string
    url: string
}
export const mapToLinkList = (list:FooterLinkListProp) =>{
    const {title,links} = list;
    const linkList:FooterLinkItem[] = [];
    links.linked_items.forEach(item=>{
        if(item.elements !== undefined){
            const {url, general_page_content__title,page_info___checkout__title} = item.elements;
            const title = general_page_content__title === undefined ? page_info___checkout__title : general_page_content__title;
            linkList.push({
                title: title === undefined ? '' : title.value === undefined ? "" : title.value,
                url: url !== undefined ? url.value : ''
            });
        }
    })
    return {
        title: title.value,
        links: linkList
    }
}

type CustomerReviewCardProp = {
    totalreviews:{
        value: number
    },
    rating:{
        value: number
    }
}
export const mapToCustomerReviewCard = (element: CustomerReviewCardProp) =>{
    const totalReview = element.totalreviews.value;
    const rating = element.rating.value;
    return {
        totalReview,
        rating
    }
}
import React from "react"
import { PromotionPopupModel } from "@models/PromotionPopup/PromotionPopup"
import { Modal, Icon } from "semantic-ui-react"
import { InfoModalButton } from "@components/InfoModal/InfoModal.styles"
import { PromotionPopupModal, PromotionPopupModalBackgroundImage, PromotionPopupModalCloseIcon, PromotionPopupModalLink } from "./PromotionModal.styles"
import { link } from "fs"
import { Link } from "gatsby"
import { backgroundImages } from "polished"
import { validatePromoService } from "@services/BasketService"
import { matchTagRegex } from "@utils/Helpers"
import { getCurrentBasket } from "@redux/localStorage/basket"
import { connect } from "react-redux"
import { currentBasketSelector } from "@redux/selectors/basketSelector"
import { deleteBasketLineRequest } from '@redux/actions/deleteBasketLine';
import { refreshBasketRequest } from '@redux/actions/refreshBasket';
import { Record } from 'immutable'
import { Dispatch } from 'redux';

interface Props {
  data: PromotionPopupModel,
  hide: (id:string)=>void,
  id:string,
  promoCode: string
  refreshBasket: Function
  apiUrl: string
}

interface State {
  modalOpen: boolean
}


class PromotionModalView extends React.Component<Props, State>{
  constructor(props: Props) {
    super(props)
    this.state = {
        modalOpen: false
    }
  }


  applyPromotionCode = () => {
    if (matchTagRegex("test")) {
        return
    }
    const basket = getCurrentBasket();
    if(!basket.summary.promotionCode){
      validatePromoService(this.props.apiUrl, this.props.promoCode, basket.basketId).then((data: any) => {
        if (data.IsValid) {
            this.props.refreshBasket(this.props.apiUrl, basket.basketId);
        } 
    })
    }    
  }

  render() {
    const {BackgroundImage, linkUrl} = this.props.data;

    return (
      <PromotionPopupModal open={true} onClose={()=>this.props.hide(this.props.id)}>
          <PromotionPopupModalCloseIcon name='x' size='massive' onClick={()=>this.props.hide(this.props.id)}/>
          <PromotionPopupModalLink to={linkUrl} onClick={()=>{this.props.hide(this.props.id); this.applyPromotionCode()}}>
            <PromotionPopupModalBackgroundImage alt={BackgroundImage.description} src={BackgroundImage.url} />
          </PromotionPopupModalLink>
      </PromotionPopupModal>
    )
  }
}
const mapStateToProps = (state: Record<any>) => {
  const cart = currentBasketSelector(state).toJS();
  return {
      cartId: cart.basketId,
      cartLineItems: cart.basketLines,
      summary: cart.summary,
      loading: cart.loading,
      success: cart.success
  };
}
const mapDispatchToProps = (dispatch: Dispatch) => ({  
  refreshBasket(apiUrl: string, basketId: string) {
      dispatch(refreshBasketRequest(apiUrl, basketId))
  },
})

const PromotionModal = connect(mapStateToProps, mapDispatchToProps)(PromotionModalView)
export default PromotionModal;
import styled from "@emotion/styled"
import { Dropdown, List } from "semantic-ui-react"
import Hyperlink from "../Hyperlink/Hyperlink"
import { H6 } from "@styles/Global.styles"

export const NavMenuLinkDropdown = styled(Dropdown)`
  line-height: 30px;
  padding-bottom: 0px;
  color: #ffffff;
  &:after {
    display: block;
    content: "";
    border-bottom: solid 3px green;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  &:hover:after {
    transform: scaleX(1);
  }
  > i {
    margin-top: -3px !important;
  }
`
export const NavListItem = styled(List.Item)`
  &&&& {
    vertical-align: middle;
    padding-bottom: 0 !important;
    padding-top: 0px !important;
    margin-bottom: 5px;
    margin-left: 25px;
  }
`
export const NavListItemDivider = styled(List.Item)`
  vertical-align: middle;
  padding-bottom: 0 !important;
  padding-top: 0px !important;
`
export const NavMenuLinkDropdownMenu = styled(Dropdown.Menu)`
  border-color: #ffffff !important;
`

export const NavMenuLinkDropdownText = styled(H6)`
  display: inline;
  color: #ffffff;
  padding-left: 5px;
  padding-right: 5px;
  text-shadow: 0px 3px 20px #00000029;
  &:hover {
    color: #ffffff;
  }
`

export const NavMenuLinkDropdownItemLink = styled(Hyperlink)`
  color: #1a2b49 !important;
  font-weight: bold;
  display: block;
`

export const DropdownListItemMessage = styled.span`
  margin-right: 30px;
  color: #1a2b49 !important;
  font-weight: bold;
`
export const DropdownListItemTitle = styled.span`
  margin-right: 30px;
  color: #1a2b49 !important;
  font-weight: bold;
  &:hover {
    border-bottom: 2px solid #1a2b49 !important;
  }
  &:active {
    color: green !important;
    border-bottom: 2px solid green !important;
  }
`

export const DropdownItem = styled(Dropdown.Item)`
  &:hover {
    color: #1a2b49 !important;
    background-color: transparent !important;
  }
`

export const UserAuthDropdownItem = styled(Dropdown.Item)`
  padding: 5px;
  cursor: pointer;
  &:hover {
    color: #1a2b49 !important;
    background-color: transparent !important;
  }
`
export const UserAuthDropdownItemInfo = styled(Dropdown.Item)`
  padding: 5px;
  &:hover {
    color: #1a2b49 !important;
    background-color: transparent !important;
  }
`

import React, { Fragment } from "react"
import { Grid, Popup } from "semantic-ui-react"
import { H6 } from "@styles/Global.styles"

import { Record } from "immutable"
import { Dispatch } from "redux"
import { currentBasketSelector } from "@redux/selectors/basketSelector"
import { connect } from "react-redux"
import { ShoppingCart, ShoppingCartBadge } from "@assets/svgs"

import {
  CartDivider,
  CartGridRow,
  ShoppingCartButton,
  CartGridColumn,
  CartItemSummary,
  PopupDetailWrapper,
  SummaryCategoryName,
  SummaryCombination,
  SummaryPrice,
  DeleteIcon,
  ShoppingCartButtonWithBadge,
  PopupEmptyCart,
} from "./ShoppingCartLink.styles"
import { deleteBasketLineRequest } from "@redux/actions/deleteBasketLine"
import { BasketLineResponseModel } from "@models/Basket/BasketLineResponseModel"
import { BasketResponseSummary } from "@models/Basket/BasketResponseModel"
import { buildPlateImageInfoFromBasket, isMobile } from "@utils/Helpers"
import { PlateImageContainer } from "@components/ShoppingCart/ShoppingCart.styles"
import { PlateImage } from "@components/PlateDesignImagesContainer/PlateDesignImagesContainer.styles"
import KpButton from "@elements/KpButton/KpButton"
import theme from "@styles/theme"
import { VehicleTypesSetting } from "@models/ProductModels"

interface ShoppingCartPopupProps {
  basketId?: string
  basketLineItems?: BasketLineResponseModel[]
  summary?: BasketResponseSummary
  apiUrl: string
  delete: (apiUrl: string, basketId: string, basketLineId: string) => void
}

const ShoppingCartPopup = (props: ShoppingCartPopupProps) => {
  return (
    <Fragment>
      <Popup.Header>
        <H6>Your Cart</H6>
        <CartDivider />
      </Popup.Header>
      <Popup.Content>
        {props.basketLineItems && props.basketLineItems.length > 0 ? (
          <ShoppingCartPopupDetail {...props} />
        ) : (
          <PopupDetailWrapper>
            <PopupEmptyCart>Your cart is Empty!</PopupEmptyCart>
          </PopupDetailWrapper>
        )}
      </Popup.Content>
    </Fragment>
  )
}

const ShoppingCartPopupDetail = (props: ShoppingCartPopupProps) => {
  const basketId = props.basketId ? props.basketId : ""
  const apiUrl = props.apiUrl
  return (
    <PopupDetailWrapper>
      {basketId !== "" && (
        <Fragment>
          <Grid>
            {props.basketLineItems &&
              props.basketLineItems.map((item, key) => {
                const isCar = item.vehicleTypeId === VehicleTypesSetting.Car.id
                const info = buildPlateImageInfoFromBasket(apiUrl, item, 150)
                return (
                  <CartGridRow key={key}>
                    <CartGridColumn width={7}>
                      <PlateImageContainer>
                        <PlateImage
                          src={info.frontUrl}
                          centered
                          width={info.frontWidth}
                          alt="front plate image"
                        />
                      </PlateImageContainer>
                      {isCar && (
                        <PlateImageContainer>
                          <PlateImage
                            src={info.backUrl}
                            centered
                            width={info.backWith}
                            alt="back plate image"
                          />
                        </PlateImageContainer>
                      )}
                    </CartGridColumn>
                    <CartGridColumn width={8}>
                      <CartItemSummary>
                        <SummaryCategoryName>
                          {item.frontPlate.rangeName}
                        </SummaryCategoryName>
                        <SummaryCombination>
                          {item.combination}
                        </SummaryCombination>
                        <SummaryPrice>
                          ${item.priceInclGst.toFixed(2)}
                        </SummaryPrice>
                      </CartItemSummary>
                    </CartGridColumn>
                    <CartGridColumn width={1}>
                      <DeleteIcon
                        color="red"
                        name="trash alternate outline"
                        onClick={() =>
                          props.delete(apiUrl, basketId, item.basketLineId)
                        }
                      />
                    </CartGridColumn>
                  </CartGridRow>
                )
              })}
          </Grid>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8} textAlign="left">
                <SummaryCategoryName>Total</SummaryCategoryName>
              </Grid.Column>
              <Grid.Column width={8} textAlign="right">
                <SummaryCategoryName>
                  ${props.summary && props.summary.finalTotal.toFixed(2)}
                </SummaryCategoryName>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                <KpButton
                  id="sclco"
                  fullWidth="mobile tablet computer"
                  buttonType="primary"
                  color={theme.brand.colors.green}
                  link="/shopping-cart/"
                >
                  CHECKOUT
                </KpButton>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Fragment>
      )}
    </PopupDetailWrapper>
  )
}

interface ShoppingCartLinkProps {
  isSimple?: boolean
  basketId?: string
  apiUrl: string
  badgeColor?: string
  fill?: string
  basketLineItems?: BasketLineResponseModel[]
  summary?: BasketResponseSummary
  delete: (apiUrl: string, basketId: string, basketLineId: string) => void
}

const ShoppingCartIcon = ({
  count,
  isSimple,
  badgeColor,
  fill,
}: {
  count: number
  isSimple?: boolean
  badgeColor?: string
  fill?: string
}) => {
  return (
    <Fragment>
      {count === 0 ? (
        <div style={isMobile() ? {paddingTop: '7px',paddingLeft: '14px'} : {}}>
          <ShoppingCart fill={fill} />
        </div>
      ) : (
        <ShoppingCartBadge
          fill={fill}
          badgeColor={badgeColor}
          counter={count}
          isSimple={isSimple}
        />
      )}
    </Fragment>
  )
}

interface ShoppingCartLinkStates {}

class ShoppingCartLink extends React.Component<
  ShoppingCartLinkProps,
  ShoppingCartLinkStates
> {
  render() {
    const {
      basketId,
      basketLineItems,
      summary,
      apiUrl,
      badgeColor,
      fill,
    } = this.props
    const count = basketLineItems ? basketLineItems.length : 0
    return (
      <Fragment>
        {count === 0 ? (
          <Popup
            trigger={
              <ShoppingCartButton>
                <ShoppingCartIcon
                  fill={fill}
                  badgeColor={badgeColor}
                  count={count}
                  isSimple={this.props.isSimple}
                />
              </ShoppingCartButton>
            }
            flowing
            on="click"
            position="bottom right"
          >
            <ShoppingCartPopup
              basketId={basketId}
              basketLineItems={basketLineItems}
              summary={summary}
              apiUrl={apiUrl}
              delete={this.props.delete}
            />
          </Popup>
        ) : (
          <Popup
            trigger={
              <ShoppingCartButtonWithBadge>
                <ShoppingCartIcon
                  fill={fill}
                  badgeColor={badgeColor}
                  count={count}
                  isSimple={this.props.isSimple}
                />
              </ShoppingCartButtonWithBadge>
            }
            flowing
            on="click"
            position="bottom right"
          >
            <ShoppingCartPopup
              basketId={basketId}
              basketLineItems={basketLineItems}
              summary={summary}
              apiUrl={apiUrl}
              delete={this.props.delete}
            />
          </Popup>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state: Record<any>) => {
  const cart = currentBasketSelector(state).toJS()
  return {
    basketId: cart.basketId,
    basketLineItems: cart.basketLines,
    summary: cart.summary,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  delete(apiUrl: string, basketId: string, basketLineId: string) {
    dispatch(deleteBasketLineRequest(apiUrl, basketId, basketLineId))
  },
})

const ShoppingCartLinkContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShoppingCartLink)

export default ShoppingCartLinkContainer

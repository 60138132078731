import React, { FunctionComponent } from 'react';
interface Props{
  fill?: string,
  width?: number
  height?: number
}

const Car: FunctionComponent<Props> = (props) =>{
  const width = '' + props.width;
  const height = '' + props.height;
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 12">
        <g id="Car" transform="translate(-89 -3766)">
          <g data-name="Group 110">
            <path data-name="Path 384" d="M104.5,3771h-5a.5.5,0,0,1-.5-.5v-2a.5.5,0,0,1,1,0v1.5h4.5a.5.5,0,0,1,0,1Z" fill={props.fill}/>
          </g>
          <g data-name="Group 111">
            <path data-name="Path 385" d="M108,3778a3,3,0,1,1,3-3A3,3,0,0,1,108,3778Zm0-5a2,2,0,1,0,2,2A2,2,0,0,0,108,3773Z" fill={props.fill}/>
          </g>
          <g data-name="Group 112">
            <path data-name="Path 386" d="M93,3778a3,3,0,1,1,3-3A3,3,0,0,1,93,3778Zm0-5a2,2,0,1,0,2,2A2,2,0,0,0,93,3773Z" fill={props.fill}/>
          </g>
          <g data-name="Group 113">
            <path data-name="Path 387" d="M105.5,3776h-10a.5.5,0,0,1,0-1h10a.5.5,0,0,1,0,1Z" fill={props.fill}/>
          </g>
          <g data-name="Group 114">
            <path data-name="Path 388" d="M111.5,3776h-1a.5.5,0,0,1,0-1h1c.115-.013.5-.238.5-.5v-2c-.007-.424-1.257-2.5-4.5-2.5a.5.5,0,0,1-.328-.123A14.205,14.205,0,0,0,98.5,3767a15.156,15.156,0,0,0-5.361.962.5.5,0,0,1-.192.038H91.708l.025.025a.5.5,0,0,1-.056.755A3.809,3.809,0,0,0,90,3772c0,2.723.5,3,.5,3a.5.5,0,0,1,0,1c-1.345,0-1.5-2.283-1.5-4a4.725,4.725,0,0,1,1.635-3.658l-.488-.488A.5.5,0,0,1,90.5,3767h2.348a16.028,16.028,0,0,1,5.652-1,15.138,15.138,0,0,1,9.182,3c3.681.079,5.318,2.55,5.318,3.5v2A1.63,1.63,0,0,1,111.5,3776Z" fill={props.fill}/>
          </g>
        </g>
      </svg>

    )
}

Car.defaultProps = {
  fill: "#707070",
  width: 24,
  height: 12
}
export default Car
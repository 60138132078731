import { convertKontentColorToHex } from "@utils/Helpers"

export type NavItem = {
    title:  string,
    url:string,
    order: number,
    sub_nav:{
        title: string,
        url: string
    }[]
}

export type AnnouncementItems = {
    elements: AnnouncementItem
}
type AnnouncementItem = {
    title__desktop___tablet_:{
        value: string
    }
    title__mobile_:{
        value:string
    }
    text_colors:{
        value:[{
            name:string
            codename:string
        }]
    }
    background_colors:{
        value:[{
            name:string
            codename:string
        }]
    }
    expired_date:{
        value: string | null
    }
}
export type TopNavMenuResult = {
    title:string
    url:string
    menu: Array<{
        title: string
        url: string
    }>
    order: number
}

export const mapToAnnouncementItems = (announcements: AnnouncementItems[]) =>{
    const items = announcements.map(announcement=>mapToAnnouncementItem(announcement.elements));
    return items;
}
const mapToAnnouncementItem = (item: AnnouncementItem) =>{
    const {background_colors,text_colors,title__desktop___tablet_,title__mobile_,expired_date} = item;
    return {
        title: title__desktop___tablet_.value,
        titleMobile: title__mobile_.value,
        backgroundColor: convertKontentColorToHex(background_colors.value[0].codename),
        textColor: convertKontentColorToHex(text_colors.value[0].codename),
        expireDate: expired_date.value
    }
}
export const mapToTopNavMenu = (data: any):NavItem[] =>{
    const items = data.filter((item: any) => item.elements.sub_nav.linked_items.length > 0).map((item: any)=>mapToTopNavMenuItem(item.elements));
    const result = items.sort((a: any,b: any)=>(a.order > b.order) ? 1 : -1);
    return result;
}

const mapToTopNavMenuItem = (data: any):NavItem =>{
    const {title,url,order,sub_nav} = data;
    const subMenuCount = sub_nav.linked_items.length;
    const subMenu = subMenuCount === 0 ? [] : sub_nav.linked_items.map((item: any) =>{
        const itemUrl = item.elements.url.value;
        const itemTitle = item.elements.title.value;
        return {
            url: itemUrl,
            title: itemTitle
        }
    })
    return {
        title: title.value,
        url: url.value,
        sub_nav: subMenu,
        order: order.value
    }
}
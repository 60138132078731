import { Grid, Form } from "semantic-ui-react";
import React, { FunctionComponent } from "react";
import { KpFormColumn } from "@styles/Global.forms";
import { KpInputFormikField, KpCheckboxFormikField } from ".";
import { FormFieldData } from "./types";
import { KpButton } from "..";
import theme from "@styles/theme";
import { FormikProps } from "formik";
import { KpEmailFormikField } from "./KpEmailFormikField";

interface FormProps {
    fields: FormFieldData[],
    formikProps: FormikProps<any>,
    validateEmailAddress?: any,
    fullWidth?: boolean,
    validatingEmail?: boolean,
    emailIsValid?: boolean
}

export const KpBaseForm: FunctionComponent<FormProps> = (props) =>{
    const {fields, formikProps,fullWidth,validateEmailAddress,validatingEmail,emailIsValid} = props;
    return (
    <Grid>
        {
            fields.map((field,key) =>{
              const tag = field.tag;
              const name = field.name;
              if(name === 'email'){
                return (<KpFormColumn key={key} {...field.width} >
                  <Form.Group>
                      <KpEmailFormikField data={field} {...formikProps} validateEmailAddress={validateEmailAddress} validatingEmail={validatingEmail} emailIsValid={emailIsValid} />
                  </Form.Group>
                </KpFormColumn>);
              }
              else if(tag === "input" || tag === "textarea"){
                return (<KpFormColumn key={key} {...field.width} >
                  <Form.Group>
                      <KpInputFormikField data={field} {...formikProps} />
                  </Form.Group>
                </KpFormColumn>);
              }else if(tag === "checkbox"){
                const checkboxText = field.checkboxLabelHtml === undefined ? field.name : field.checkboxLabelHtml;
                return (<KpFormColumn mobile={16} tablet={16} computer={16} >
                                <Form.Group>
                                  <KpCheckboxFormikField id={field.id} name={field.name} value={field.name} text={checkboxText} errors={formikProps.errors} />
                                </Form.Group>
                        </KpFormColumn>
                )
              }else{
                return (
                  <div key={key}>The input tag type is not mapped yet. please update KpBaseForm file.</div>
                )
              }
            })
        }
      <KpFormColumn mobile={16} tablet={16} computer={16} >
        {
          fullWidth ?
          <KpButton fullWidth="mobile tablet computer" id="kpbfsmt"  type='submit'
            loading={formikProps.status.isSubmitting} buttonType='primary' color={theme.brand.colors.blue} >SUBMIT</KpButton>
          :
          <KpButton  id="kpbfsmt"  type='submit'
            loading={formikProps.status.isSubmitting} buttonType='primary' color={theme.brand.colors.blue}>SUBMIT</KpButton>

        }
      </KpFormColumn>
    </Grid>);
}
import React from 'react'
import { Link } from "gatsby"

interface HyperlinkProps{
    contentType?: string,
    url?: string, 
    children: object | string
    openInNewTab?: boolean
}


const Hyperlink = (props: HyperlinkProps) =>{
    const {contentType, url, openInNewTab, children, ...others} = props;
    let to = url;
    if(!to){
        return (<span>{children}</span>)
    }
    const isInternalLink = !to.startsWith("http");
    if(isInternalLink){
        if(!to.startsWith("/")){
            to = "/" + to;
        }
        if(!to.includes('?')){
            if(!to.endsWith('/')){
                to = to + '/'
            }
        }
        //render the relative url for dynamic generated content types url
        if(contentType === "KontentItemPlateDesignPage"){
            to = "/plate-design" + to;
        }
    }
    return (
        openInNewTab === true?
        <a href={to} target="_blank" rel="noopener noreferrer" {...others}>{children}</a>
        :
        isInternalLink ? 
        <Link to={to} {...others}>{children}</Link>
        :
        <a href={to} {...others}>{children}</a>
    )
}

export default Hyperlink;
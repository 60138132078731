import theme from './theme'
import facepaint from 'facepaint'

type Rules = {
    [key: string]: string
}

/** Create object with breakpoints as min-width rules. */
const rules: Rules = Object.entries(theme.breakpoints).reduce(
  (acc, [key, val]) => ({ ...acc, [key]: `(min-width: ${val}px)` }),
  {},
);

/** Create object with rules as full media queries. */
// const mq = Object.entries(rules).reduce(
//   (acc, [key, val]) => ({ ...acc, [key]: `@media ${val}` }),
//   {},
// );


const query = Object.keys(rules).map(key=>`@media ${rules[key]}`);

const mq = facepaint(query);

export { mq as default, rules };
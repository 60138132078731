import React from 'react'
import KpButton from '@elements/KpButton/KpButton';
import styled from '@emotion/styled';
import mq from '@styles/mq'
import { Link } from 'gatsby';

export const NavMenuInternalLinkItem = styled(Link)`
    line-height:30px;
    color: #FFFFFF;
    &:after{
        display:block;
        content:'';
        border-bottom: solid 3px green;
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
    }
    &:hover:after{
        transform: scaleX(1)
    }
`
export const NavMenuExternalLinkItem = styled.a`
    line-height:30px;
    color: #FFFFFF;
    &:after{
        display:block;
        content:'';
        border-bottom: solid 3px green;
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
    }
    &:hover:after{
        transform: scaleX(1)
    }
`

export const NavMenuLinkItemContent = styled.span`
    padding-left: 10px;
    padding-right: 10px;
`

export const NavMenuLinkItemText = styled.span`
    ${mq({
        fontSize:['14px','16px','16px'],
        lineHeight: ['16','18px','22px']
    })}
    display: inline;  
    text-shadow: 0px 3px 20px #00000029;
    color: #FFFFFF;
    font-weight: bold;
    letter-spacing: 0;
`

export const Divider = styled.div`
  width: 3px;
  height: 33px;
  content: '';
  border-right: 2px solid #FFFFFF;
`

export const NavigationMenuButton = styled(({darkTheme, ...otherProps}) => <KpButton {...otherProps}/>)`
    &&&&& {
        margin: 0 40px;
        padding-left: 25px;
        padding-right: 25px;
        min-width: 0px;
        :hover{
            background-color: ${p => p.darkTheme ? 'white' : 'black'};
            color: ${p => p.darkTheme ? 'black' : 'white'};
            border-color: ${p => p.darkTheme ? 'white' : 'black'};
        }        
    }    
`
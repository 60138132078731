import React, { FunctionComponent } from 'react';

interface Props{
  fill?: string,
  width?: number
  height?: number
}

const Motorbike: FunctionComponent<Props> = (props) =>{
  const width = '' + props.width;
  const height = '' + props.height;
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 14.001">
        <g transform="translate(-281 -3572.999)">
          <g  data-name="Group 15">
            <path  data-name="Path 85" d="M301,3587a4,4,0,1,1,4-4A4,4,0,0,1,301,3587Zm0-7a3,3,0,1,0,3,3A3,3,0,0,0,301,3580Z" fill={props.fill}/>
          </g>
          <g data-name="Group 16">
            <path data-name="Path 86" d="M285,3587a4,4,0,0,1-1.633-7.645.5.5,0,0,1,.409.913,3.024,3.024,0,1,0,4.128,2.019.5.5,0,0,1,.971-.238A3.963,3.963,0,0,1,285,3587Z" fill={props.fill}/>
          </g>
          <g data-name="Group 17">
            <path data-name="Path 87" d="M299.5,3577h-.016c-.356-.012-3.484-.162-3.484-2s3.128-1.988,3.484-2a.532.532,0,0,1,.364.141.5.5,0,0,1,.152.359v3a.5.5,0,0,1-.5.5Zm-.5-2.958c-.852.1-2,.4-2,.958,0,.538,1.117.851,2,.959Z" fill={props.fill}/>
          </g>
          <g data-name="Group 18">
            <path data-name="Path 88" d="M296.5,3575a.515.515,0,0,1-.158-.025l-3-1a.5.5,0,0,1,.316-.95l3,1a.5.5,0,0,1-.158.975Z" fill={props.fill}/>
          </g>
          <g data-name="Group 19">
            <path data-name="Path 89" d="M301,3583.5a.5.5,0,0,1-.434-.252l-4-7a.5.5,0,1,1,.868-.5l4,7a.5.5,0,0,1-.434.748Z" fill={props.fill}/>
          </g>
          <g data-name="Group 20">
            <path data-name="Path 90" d="M288.5,3583.5H285a.5.5,0,0,1-.447-.724l1-2a.5.5,0,0,1,.894.448l-.638,1.276H288.5a.5.5,0,0,1,0,1Z" fill={props.fill}/>
          </g>
          <g data-name="Group 21">
            <path data-name="Path 91" d="M294.5,3584h-4a.5.5,0,0,1-.281-.087c-1.6-1.088-6.193-3.913-8.219-3.913a.5.5,0,0,1-.5-.479.935.935,0,0,1,.293-.665c.5-.516,1.575-.8,3.207-.849v-.507a.5.5,0,0,1,.177-.382.49.49,0,0,1,.407-.111c.029,0,2.908.493,3.916.493a.5.5,0,0,1,.27.079A11.328,11.328,0,0,1,296.5,3575a.665.665,0,0,1,.641.557,2.506,2.506,0,0,1,.068.655C297.209,3577.389,296.2,3584,294.5,3584Zm-3.847-1H294.5c.683-.452,1.85-5.694,1.714-6.994a10.51,10.51,0,0,0-6.356,2.843.5.5,0,0,1-.858-.349v-.022c-.9-.067-2.237-.268-3-.389v.411a.5.5,0,0,1-.5.5,9.39,9.39,0,0,0-2.207.205C286.006,3579.948,289.88,3582.483,290.653,3583Z" fill={props.fill}/>
          </g>
        </g>
      </svg>

    )
}

Motorbike.defaultProps = {
  fill: "#707070",
  width: 24,
  height: 14
}
export default Motorbike
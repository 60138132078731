import React from 'react'
import styled from '@emotion/styled'
import theme from '@styles/theme'
import mq from '@styles/mq'

import Hyperlink from '@elements/Hyperlink/Hyperlink'
import { Button } from 'semantic-ui-react'
import { isMobile } from '@utils/Helpers'

export const ButtonWrapper = styled(({mobileFullWidth,tabletFullWidth,desktopFullWidth, ...otherProps}) => <Hyperlink {...otherProps}/>)`
    display: inline-block;
    margin-top: 5px;
    margin-right: ${isMobile() ? '0px' : '10px'};
    ${props => mq(
        {width: [props.mobileFullWidth, props.tabletFullWidth, props.desktopFullWidth]},
        {marginLeft: ['0px', '10px', '10px']})}
`

export const PrimaryButton = styled(({textColor,color, onClick, mobileFullWidth,tabletFullWidth,desktopFullWidth, ignoreMinWidth, id, darkTheme, ...otherProps}) => <Button onClick={onClick} {...otherProps}/>)`
    &&&&{

        ${props => mq({
            fontSize:['14px','14px'],
            lineHeight:['20px','20px'],
            paddingTop:['14px','14px'],
            paddingBottom: ['10px','10px'],
            width: [props.mobileFullWidth, props.tabletFullWidth, props.desktopFullWidth],
            paddingLeft: [props.mobileFullWidth === '100%' ? "0px" : '40px' , props.tabletFullWidth === '100%' ? "0px" : '32px' , props.desktopFullWidth === '100%' ? "0px" : '32px' ],
            paddingRight: [props.mobileFullWidth === '100%' ? "0px" : '40px' , props.tabletFullWidth === '100%' ? "0px" : '32px' , props.desktopFullWidth === '100%' ? "0px" : '32px' ]
        })}
        ${props => !props.ignoreMinWidth ? {minWidth: '0px'} : {minWidth: '200px'}}

        font-family: itc-avant-garde-gothic-pro, sans-serif;
        border:none;
        letter-spacing: 0.56px;
        border-radius: 5px;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        text-transform: uppercase !important;
        :focus{
            outline: 0;
        }
        background-color: ${props => props.color ? props.color : theme.desktopTheme.buttonColor};
        border: 3px solid ${props => props.color ? props.color : theme.desktopTheme.buttonColor};
        color: ${props => props.textColor ? props.textColor : theme.desktopTheme.buttonColor};
        :hover {
            background-color: ${props => props.loading ? props.color : (props.id === 'qcfca' || props.darkTheme) ? theme.brand.colors.white : theme.brand.colors.black};
            border-color: ${props => props.loading ? props.color : (props.darkTheme ? theme.brand.colors.white : theme.brand.colors.black)};
            color: ${props => (props.id === 'qcfca' || props.darkTheme) ? theme.brand.colors.black :  theme.brand.colors.white};
            .subtitle-desktop{
            color: black;
            }
        }
        &.disabled{
            color: ${theme.brand.colors.white};
            background-color: ${theme.brand.colors.darkGrey};
            border: 2px solid ${theme.brand.colors.darkGrey};
            cursor: default;
            opacity: 1 !important
        }
    }

`

export const SecondaryButton = styled(({color, onClick,ignoreMinWidth,  mobileFullWidth,tabletFullWidth,desktopFullWidth, id, darkTheme, ...otherProps}) => <Button onClick={onClick} {...otherProps}/>)`
    &&&& {
        ${mq({
            fontSize:['14px','14px'],
            lineHeight:['20px','20px'],
            paddingTop:['14px','14px'],
            paddingBottom: ['10px','10px']
        })}
        ${props => mq({width: [props.mobileFullWidth, props.tabletFullWidth, props.desktopFullWidth]})}
        ${props => !props.ignoreMinWidth && {minWidth: '160px'}}
        ${props => mq({paddingLeft: [props.mobileFullWidth === '100%' ? "0px" : '40px' , props.tabletFullWidth === '100%' ? "0px" : '40px' , props.desktopFullWidth === '100%' ? "0px" : '40px' ]})}
        ${props => mq({paddingRight: [props.mobileFullWidth === '100%' ? "0px" : '40px' , props.tabletFullWidth === '100%' ? "0px" : '40px' , props.desktopFullWidth === '100%' ? "0px" : '40px' ]})}
        font-family: itc-avant-garde-gothic-pro, sans-serif;
        border:none;
        border-radius: 5px;
        letter-spacing: 0.56px;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        text-transform: uppercase !important;
        :focus{
            outline: 0;
        }
        background-color: ${theme.brand.colors.white};
        border: 3px solid ${props => props.color};
        color: ${props => props.color};

        :hover {
            background-color: ${props => props.loading ? theme.brand.colors.white : theme.brand.colors.green };
            border-color: ${props => props.loading ? theme.brand.colors.white : theme.brand.colors.green };
            color: ${theme.brand.colors.black};
        }
        &.disabled{
            color: ${theme.brand.colors.white};
            background-color: ${theme.brand.colors.darkGrey};
            border: 2px solid ${theme.brand.colors.darkGrey};
            cursor: default;
            opacity: 1 !important
        }
    }

`

export const AlternateButton = styled(({color, onClick, id, ...otherProps}) => <Button onClick={onClick} {...otherProps}/>)`
    &&&& {
        ${mq({
            fontSize:['14px','14px'],
            lineHeight:['20px','20px']
        })}
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        letter-spacing: 0.56px;
        background-color: transparent;
        font-family: itc-avant-garde-gothic-pro, sans-serif;
        border:none;
        border-radius: 0;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        :focus{
            outline: 0;
        }
        border-bottom: 2px solid ${props => props.color} !important;
        color: ${props => props.color};
        :hover {
            background-color: transparent !important;
            color: ${props => props.color};
        }
        :disabled{
            color: ${theme.brand.colors.disabled};
            cursor: default;
        }
    }
`

export const DarkModeSecondaryButton = styled(({color, onClick,ignoreMinWidth,  mobileFullWidth,tabletFullWidth,desktopFullWidth,id, ...otherProps}) => <Button onClick={onClick} {...otherProps}/>)`
    &&&& {
        ${mq({
            fontSize:['14px','14px'],
            lineHeight:['20px','20px'],
            paddingTop:['14px','14px'],
            paddingBottom: ['12px','9px']
        })}
        ${props => mq({width: [props.mobileFullWidth, props.tabletFullWidth, props.desktopFullWidth]})}
        ${props => !props.ignoreMinWidth && {minWidth: '160px'}}
        ${props => mq({paddingLeft: [props.mobileFullWidth === '100%' ? "0px" : '40px' , props.tabletFullWidth === '100%' ? "0px" : '40px' , props.desktopFullWidth === '100%' ? "0px" : '40px' ]})}
        ${props => mq({paddingRight: [props.mobileFullWidth === '100%' ? "0px" : '40px' , props.tabletFullWidth === '100%' ? "0px" : '40px' , props.desktopFullWidth === '100%' ? "0px" : '40px' ]})}
        font-family: itc-avant-garde-gothic-pro, sans-serif;
        border:none;
        border-radius: 5px;
        letter-spacing: 0.56px;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        text-transform: uppercase !important;
        :focus{
            outline: 0;
        }
        background-color: ${theme.brand.colors.black};
        border: 3px solid ${props => props.color};
        color: ${props => props.color};
        ${props => mq({marginRight: ['', '12px' , '12px']})};
        ${props => mq({marginLeft: ['', '12px' , '12px']})};

        :hover {
            background-color: ${props => props.loading ? theme.brand.colors.white : theme.brand.colors.green };
            border-color: ${props => props.loading ? theme.brand.colors.white : theme.brand.colors.green };
            color: ${theme.brand.colors.black};

            .subtitle-desktop{
                color: black;
            }
        }
        &.disabled{
            color: ${theme.brand.colors.white};
            background-color: ${theme.brand.colors.darkGrey};
            border: 2px solid ${theme.brand.colors.darkGrey};
            cursor: default;
            opacity: 1 !important
        }
    }

`
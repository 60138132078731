import React, { FunctionComponent } from 'react';

interface Props{
    fill?: string,
    width?: number
    height?: number
  }
const ShoppingCart: FunctionComponent<Props> = (props) =>{
    const width = '' + props.width;
    const height = '' + props.height;
    return (
        <svg data-name="Shopping Cart" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 24.067">
        <path data-name="Path 259" d="M25.719,4.891a1.316,1.316,0,0,0-1.028-.5H6.3L5.431.981A1.309,1.309,0,0,0,4.166,0H1.307a1.307,1.307,0,0,0,0,2.615H3.15L6.406,15.278a1.309,1.309,0,0,0,1.266.981H22.4a1.315,1.315,0,0,0,1.272-.992l2.288-9.258A1.3,1.3,0,0,0,25.719,4.891ZM21.381,13.65H8.688L6.982,7.006H23.027Z" fill={props.fill}/>
        <path data-name="Path 260" d="M92.618,286.6a3.518,3.518,0,1,0,3.518,3.518A3.521,3.521,0,0,0,92.618,286.6Zm0,4.653a1.141,1.141,0,1,1,1.141-1.141A1.141,1.141,0,0,1,92.618,291.253Z" transform="translate(-83.805 -269.569)" fill={props.fill}/>
        <path data-name="Path 261" d="M286.318,286.6a3.518,3.518,0,1,0,3.518,3.518A3.521,3.521,0,0,0,286.318,286.6Zm0,4.653a1.141,1.141,0,1,1,1.141-1.141A1.141,1.141,0,0,1,286.318,291.253Z" transform="translate(-265.995 -269.569)" fill={props.fill} />
        </svg>
    )
}
ShoppingCart.defaultProps = {
    fill: "#fff",
    width: 26,
    height: 24
}

export default ShoppingCart;
import { ThemeProvider } from 'emotion-theming';
import React from 'react';

import theme from '@styles/theme';
import darkTheme from '@styles/dark-theme';

const ThemeWrapper = ({children,themeName}:{children: object | string, themeName: string}) => (
  themeName == "default" ? <ThemeProvider theme={theme}>{children}</ThemeProvider> : <ThemeProvider theme={darkTheme}>{children}</ThemeProvider>
);
ThemeWrapper.defaultProps = {
  themeName: 'default',
};

export default ThemeWrapper;

/** Get formatted date string. */
/** refer to this cheat sheet https://devhints.io/wip/intl-datetime */
export function formatDate(date: number | Date) {
  const formatter = new Intl.DateTimeFormat('en-NZ', {
    second: '2-digit',
    minute: '2-digit',
    hour: '2-digit',
    day: 'numeric',
    month: 'long',
    timeZone: 'Pacific/Auckland',
    weekday: 'long',
    year: 'numeric',
    timeZoneName: 'short'
  });

  return formatter.format(date);
}

export function formatKenticoDateToddMMyy(kenticoDateString: string){
  const date = new Date(kenticoDateString);
  const formatter = new Intl.DateTimeFormat('en-NZ',{
    day: 'numeric',
    month: 'short',
    year: '2-digit'
  });
  return formatter.format(date);
}

export function formatTimestampDateToddMMyy(timestamp: number){
  const formatter = new Intl.DateTimeFormat('en-NZ',{
    day: 'numeric',
    month: 'short',
    year: '2-digit'
  });
  return formatter.format(timestamp*1000);
}


export function formatTime(date: number | Date) {
  const formatter = new Intl.DateTimeFormat('en-NZ', {
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
    timeZone: 'Pacific/Auckland',
  });

  return formatter.format(date);
}
export function getFriendlyDateTime(date: number){
  const current = new Date(Date()).toISOString();
  const currentDate = Date.parse(current);
  const dateDiff = currentDate - date;
  const oneDay = 60*60*24*1000;
  if(dateDiff < 60*1000){
    return Math.floor(dateDiff/1000) + ' seconds ago'
  }else if(dateDiff < 2*60*1000){
    return '1 minute ago'
  }else if(dateDiff < 60*60*1000){
    return Math.floor(dateDiff/(60*1000)) + ' minutes ago'
  }else if(dateDiff < 2*60*60*1000){
    return '1 hour ago'
  }else if(dateDiff < oneDay){
    return Math.floor(dateDiff/(60*60*1000)) + ' hours ago'
  }else if(dateDiff < oneDay * 2){
    return "1 day ago"
  }else {
    return Math.floor(dateDiff/(60*60*24*1000)) + ' days ago'
  }
}
import { FormFieldData } from "./types";
import * as Yup from "yup"

export const buildFormInitialValues = (input: FormFieldData[]) =>{
    let initialValues:any = {};
    input.forEach(item=>{
        initialValues[item.id] = item.initialValue;
    })
    return initialValues;
}

export const buildValidationSchema = (input: FormFieldData[]) =>{
    let validationObject:any = {};
    input.forEach(item=>{
        const key= item.id;
        const name = item.name;
        const isRequired = item.isRequired;
        if(item.tag === "input" || item.tag === "textarea"){
            if(item.regExpValidation !== undefined && isRequired){
                validationObject[key] = Yup.string().required(item.requiredErrorMessage).matches(item.regExpValidation.regex, item.regExpValidation.errorMessage);
            }else if(isRequired){
                validationObject[key] = Yup.string().required(item.requiredErrorMessage);
            }
        }else if(item.tag === "checkbox"){
            if(isRequired){
                validationObject[name] = Yup.string().required(item.requiredErrorMessage);
            }
        }
    });
    return Yup.object(validationObject);
}
import Axios from 'axios'
import { BasketApiUrls } from '@utils/BasketApiUrls';

const getLaybyQuotesForAmount = async (apiUrl:string, amount:number, promoCode?: string) =>{
    const url = BasketApiUrls.getLaybyQuotesByAmount(apiUrl,amount, promoCode ? btoa(promoCode) : '');
    const result = await Axios.get(url);
    
    return result.data.Data;
}

export const LaybyService = {
    getLaybyQuotesForAmount: getLaybyQuotesForAmount
}
import React, { FunctionComponent } from 'react';

interface Props{
  fill?: string,
  width?: number
  height?: number
}
const YouTube: FunctionComponent<Props> = (props) =>{
  const width = '' + props.width;
  const height = '' + props.height;
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 30">
        <g transform="translate(-809 -3149)">
          <g data-name="youtube (2)" transform="translate(809 3149)">
            <path data-name="Path 67" d="M224.113,213.66l4.879-2.81-4.879-2.81Zm0,0" transform="translate(-210.981 -195.849)" fill={props.fill}/>
            <path data-name="Path 68" d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm9.373,15.015a24.757,24.757,0,0,1-.386,4.509,2.349,2.349,0,0,1-1.652,1.652A57.012,57.012,0,0,1,15,21.563a54.972,54.972,0,0,1-7.335-.4,2.349,2.349,0,0,1-1.652-1.652A24.648,24.648,0,0,1,5.627,15a24.741,24.741,0,0,1,.386-4.509A2.4,2.4,0,0,1,7.665,8.824,57.012,57.012,0,0,1,15,8.438a54.855,54.855,0,0,1,7.335.4,2.349,2.349,0,0,1,1.652,1.652,23.494,23.494,0,0,1,.386,4.524Zm0,0" fill={props.fill}/>
          </g>
        </g>
      </svg>
    )
}

YouTube.defaultProps = {
  fill: "#fff",
  width: 30,
  height: 30
}
export default YouTube
import styled from "@emotion/styled"
import { Segment, Icon, Container } from "semantic-ui-react"
import { H6 } from "@styles/Global.styles"
import { Logo } from "@assets/svgs"
import theme from "@styles/theme"

export const LogoIcon = styled(Logo)`
  float: left;
`

export const HeaderSegment = styled(Segment)`
  background-color: transparent !important;
  width: 100%;
  z-index: 999;
  height: 75px;
  padding-top: 25px;
  &&&& {
    position: absolute;
  }
`
export const SimpleTopNavContainer = styled.div`
  float: right;
`
export const PhoneLink = styled.a``
export const MessageContainer = styled.span``
export const MessageContainerLink = styled.a`
  color: white !important;
  font-weight: bold;
`

export const SimpleShoppingCartContainer = styled.div`
  padding-top: 8px;
  padding-left: 20px;
  display: inline-block;
`

export const SimpleTopNavMessage = styled.div`
  padding-right: 20px;
  color: white;
  line-height: 35px;
  padding-top: 5px;
  border-right: 2px solid white;
  float: left;
  font-size: 15px;
  font-weight: light;
  &: > b {
    font-size: 18px;
    font-weight: bold;
  }
`

export const SimpleHeaderSegment = styled(Segment)`
  background-color: #000 !important;
  width: 100%;
  z-index: 100;
  height: 100px;
  padding-top: 10px;
  margin: 0 !important;
`
export const SimpleHeaderContainer = styled(Container)`
  padding-top: 16px;
  padding-bottom: 16px;
`
export const HeaderText = styled(H6)`
  text-shadow: 0px 3px 20px #00000029;
  color: #ffffff;
`

export const MobileHeaderSegment = styled(Segment)`
  background-color: transparent !important;
  width: 100%;
  z-index: 999;
  height: 50px;

  &&&& {
    position: absolute;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 6px;
  }
`
export const SimpleMobileHeaderSegment = styled(Segment)`
  background-color: ${theme.brand.colors.black} !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  width: 100%;
  z-index: 999;
  height: 75px;
  padding-top: 10px;
`
export const ShoppingCartWrapper = styled.div`
  float: right;
  padding-top: 5px;
`
export const MobileNavWrapper = styled.div`
  float: right;
  padding-top: 5px;
`
export const PhoneLinkWrapper = styled.div`
  float: left;
  padding-top: 5px;
  padding-right: 16px;
  border-right: 2px solid #fff;
  color: white;
`

export const DesktopPhoneLinkWrapper = styled.div`
  float: right;
  padding-top: 2px;
  height: 26px;
  padding-left: 20px;
`

export const MobileHeaderLogo = styled(Logo)`
  padding-right: 20px;
  padding-left: 20px;
  display: inline-block;
`

export const SidebarIcon = styled(Icon)`
  color: #ffffff !important;
  position: relative;
  display: inline-block;
  vertical-align: top !important;
  margin-top: 9px !important;
  margin-right: 16px !important;
  cursor: pointer;
`
export const SimpleTopNavAuth = styled.div`
  padding-right: 20px;
  color: white;
  line-height: 35px;
  border-right: 2px solid white;
  float: left;
  font-size: 15px;
  font-weight: light;
  cursor: pointer;
  padding-top: 3px;
  &: > b {
    font-size: 18px;
    font-weight: bold;
  }
`
export const DesktopSingoutLinkWrapper = styled.div`
  float: right;
  height: 26px;
  padding-left: 10px;
`
export const DealerTopNavContainer = styled.div`
  padding-right: 20px;
  color: white;
  line-height: 35px;
  float: right;
  font-size: 15px;
  font-weight: light;
  cursor: pointer;
  padding-top: 3px;
  &: > b {
    font-size: 18px;
    font-weight: bold;
  }
`
export const DarkMobileHeaderSegment = styled(Segment)`
  background-color: ${theme.brand.colors.black} !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  width: 100%;
  z-index: 999;
  height: 70px;
  padding-top: 10px;
`
import styled from "@emotion/styled"
import { Button, Divider, Grid, Icon } from "semantic-ui-react"
import mq from "@styles/mq"
import theme from "@styles/theme"

export const CartDivider = styled(Divider)`
  &&&& {
    border-width: 2px;
  }
`

export const CartGridRow = styled(Grid.Row)`
  width: 320px;
  padding: 0 0 10px 0 !important;
  border-bottom: 2px solid ${theme.brand.colors.lightGrey};
  margin: 12px 1rem 0px 1rem !important;
`
export const CartGridColumn = styled(Grid.Column)`
  padding: 0 !important;
`
export const PopupDetailWrapper = styled.div`
  max-width: 320px;
  display: block;
`
export const PopupEmptyCart = styled.div`
  text-align: center;
  padding: 20px 0;
`
export const CartItemSummary = styled.div`
  padding: 0 8px 0 8px;
`
export const NavLinkItem = styled(Button)`
  line-height: 30px;
  color: #1a2b49;
  &:hover {
    color: #1a2b49;
  }
  &:after {
    display: block;
    content: "";
    border-bottom: solid 3px green;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  &:hover:after {
    transform: scaleX(1);
  }
`

export const NavLinkItemContent = styled.span`
  padding-left: 10px;
  padding-right: 10px;
`

export const NavLinkItemText = styled.span`
  ${mq({
    display: ["none", "none", "inline"],
  })}
  color: #1a2b49;
  padding-left: 5px;
  font-weight: bold;
`

export const ShoppingCartButton = styled.div`
  ${mq({
    paddingRight: ["0px", "10px", "10px"],
  })}
  display:inline-block;
  vertical-align: middle;
  padding-left: 16px;
  padding-top: 8px;
  cursor: pointer;
`
export const ShoppingCartButtonWithBadge = styled.div`
  ${mq({
    paddingRight: ["0px", "10px", "10px"],
  })}
  display:inline-block;
  vertical-align: middle;
  padding-left: 16px;
  cursor: pointer;
`
export const UserIcon = styled(Icon)`
  color: #ffffff;
  margin-left: 20px;
`
export const SummaryCategoryName = styled.div`
  font-size: 13px;
  font-weight: bold;
  line-height: 19px;
`
export const SummaryCombination = styled.div`
    font-size: 13px;
    color: ${theme.brand.colors.darkGrey}
    line-height: 19px;
`
export const SummaryPrice = styled.div`
    font-size: 13px;
    color: ${theme.brand.colors.darkGrey}
    line-height: 19px;
`
export const DeleteIcon = styled(Icon)`
  cursor: pointer;
`
export const UserIconWrapper = styled.div`
  ${mq({
    paddingRight: ["16px", "24px", "24px"],
  })}
  display:inline-block;
  margin-top: -8px;
  vertical-align: middle;
  cursor: pointer;
`

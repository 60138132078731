import React from "react"
import { Formik } from "formik"
import { Grid, Form } from "semantic-ui-react"
import {
  buildValidationSchema,
  buildFormInitialValues,
} from "@elements/Forms/helper"
import { KpBaseForm } from "@elements/Forms/KpBaseForm"
import { FormFieldData } from "@elements/Forms/types"
import { Paragraph1 } from "@styles/Global.styles"
import theme from "@styles/theme"
import { SubscriptionService } from "@services/index"
import { EmailValidationService } from "@services/EmailValidationService"

interface CompetitionFormProps {
  apiUrl: string
  emailValidationToken: string
  fields: FormFieldData[]
  campaignName: string
  competitionName: string
  success: string
}

interface CompetitionFormStates {
  currentEmail: string
  validatingEmail: boolean
  emailIsValid?: boolean
}

class CompetitionForm extends React.Component<
  CompetitionFormProps,
  CompetitionFormStates
> {
  constructor(props: CompetitionFormProps) {
    super(props)
    this.state = {
      currentEmail: "",
      validatingEmail: false,
    }
  }

  validationSchema = buildValidationSchema(this.props.fields)

  intialStatus = {
    isSubmitting: false,
    isSubmitted: false,
  }
  validateEmailAddress = (value: any) => {
    if (this.state.currentEmail !== value) {
      this.setState({
        currentEmail: value,
        validatingEmail: true,
        emailIsValid: undefined,
      })
      let error
      return EmailValidationService.validate(
        this.props.emailValidationToken,
        value,
        false
      ).then((result: any) => {
        if (result.success == true) {
          this.setState({ validatingEmail: false, emailIsValid: true })
        } else {
          error = "Invalid Email"
          this.setState({ validatingEmail: false, emailIsValid: false })
          return error
        }
      })
    } else if (this.state.emailIsValid == false) {
      return "Invalid Email"
    }
  }
  insitialValues = buildFormInitialValues(this.props.fields)

  submitForm = (values: any, actions: any) => {
    if (!this.state.validatingEmail) {
      actions.setStatus({
        isSubmitting: true,
      })
      SubscriptionService.subscribeUserRequest(
        this.props.apiUrl,
        values,
        this.props.competitionName,
        {
          campaignName: this.props.campaignName
        }, false
      ).then(data => {
        actions.setStatus({
          isSubmitting: false,
          isSubmitted: true,
        })
      })
    } else {
      actions.setSubmitting(false)
    }
  }
  render() {
    const { fields, success } = this.props

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Formik
              initialValues={this.insitialValues}
              validationSchema={this.validationSchema}
              initialStatus={this.intialStatus}
              validateOnChange={false}
              onSubmit={(values: any, actions: any) =>
                this.submitForm(values, actions)
              }
            >
              {props => (
                <Form onSubmit={props.handleSubmit}>
                  <Grid columns={16}>
                    <Grid.Row>
                      {!props.status.isSubmitted && (
                        <Grid.Column mobile={16} tablet={16} computer={14}>
                          <KpBaseForm
                            formikProps={props}
                            fields={fields}
                            validateEmailAddress={this.validateEmailAddress}
                            validatingEmail={this.state.validatingEmail}
                            emailIsValid={this.state.emailIsValid}
                          />
                        </Grid.Column>
                      )}
                      {props.status.isSubmitted && (
                        <Grid.Column verticalAlign="middle" width={16}>
                          <Paragraph1
                            color={theme.brand.colors.black}
                            dangerouslySetInnerHTML={{ __html: success }}
                          ></Paragraph1>
                        </Grid.Column>
                      )}
                    </Grid.Row>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}
export default CompetitionForm

import React from 'react'
import styled from '@emotion/styled'
import { Container, Segment, Header, ListItem, List} from 'semantic-ui-react'
import mq from '@styles/mq';
import { LazyLoadImage } from '@elements/index';

export const FooterContainer = styled(Container)`
    line-height: 25px;
    color: #ffffff;
    padding: 20px 0 20px 0;
`;
export const FooterNavigationContainer = styled(Container)`
    margin-bottom: 20px;
`;

export const SignupContainer = styled(Container)`
    
`;

export const FooterSegment = styled(({backgroundColor,version, ...rest})=> <Segment {...rest} />)`
    &&&&{
        display: grid;
        background-color: ${props => props.backgroundColor} !important;
        margin: 0 !important;
        border: none !important;
        border-radius: 0 !important;
    }
`
export const FooterColumnListItem = styled(ListItem)`
    margin-bottom: 10px;
    margin-right: 10px;
    &.small {
        a, span {
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0;
            color: white !important;
        }
    }
    &.large {
        a, span {
            font-size: 18px;
            line-height: 26px;
            font-weight: bold;
            letter-spacing: 0;
            color: white !important;
        }
    }
`

export const FooterColumnRightListItem = styled(ListItem)`
    margin-bottom: 10px;
    margin-right: 10px;
    &.small {
        a, span {
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0;
            color: white !important;
        }
    }
    ${mq({
        marginTop: ['0px !important','33px !important','33px !important']
    })}
`

export const FooterColumnHeader = styled(Header)`
    opacity: 0.5;
    font-size: 13px !important;
    line-height: 19px !important;
    text-transform: uppercase !important;
`

export const FooterLogoImage = styled(LazyLoadImage)`
    width: 200px;
`
export const FooterCopyrightList = styled(List)`
    ${mq({
        float: ["left", "right","right"]
    })}
`
export const GoogelReviewSummary = styled.div`
  display: flex;
  background-color: transparent;
  width: 100%;
  height: 120px;
  color: #ffffff;
  justify-content: left;
  align-items: center;
  padding-bottom: 0px;
`
export const GoogleLogoIcon = styled.div`
padding: 8px;
width: 56px;
height: 56px;
display: flex;
background-color: #ffffff;
border-radius: 28px;
justify-content: center;
align-items: center;
`
export const ReviewSection = styled.div`
    padding-left: 10px;
`

export const RatingMessage = styled.div`
    font-size: 14px;
    font-weight: light;
`
export const FooterLogoWrapper = styled.div`
    display: flex;
    background-color: transparent;
    justify-content: left;
    align-items: center;
    padding-bottom: 20px;
`
export const GoogleReviewIconWrapper = styled.a`
`

export const FooterPaymentBanner = styled.div`
    background-color: #fff !important;
    width: 100%;
    ${mq({
        height: ["168px", "100px;", "100px;"],
      })}
`

export const FooterPaymentBox = styled.div`
    background-color: transparent !important;
    ${mq({
        width: ["300px", "527px", "527px"],
        paddingTop: ["10px;", "20px;", "20px;"],
      })}
    margin: auto;
    text-align: center;
    color: #949494;
    font-size: 12px;

    span {
        ${mq({
            fontSize: ["14px;", "14px;", "14px;"],
            width: ["100%;", "unset", "100px"],
            marginBottom: ["8px;", "unset", "unset"],
            marginRight: ["0px", "0px", "0px"],
            paddingTop: ["0px;", "0px;", "3px;"],
          })}
        font-weight: 300;
        display: block;
        float: left;
        margin-top: 9px;
    }

    p{
        ${mq({
            marginTop: ["6px;", "unset", "unset"],
          })}
    }

    .cards-images{
        height: 34px;
        margin-left:4px;
        margin-right:4px;
    }
`
import React, { FunctionComponent } from 'react';


interface Props{
  fill?: string,
  width?: number
  height?: number
}

const Phone: FunctionComponent<Props> = (props) =>{
  const width = '' + props.width;
  const height = '' + props.height;
    return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 31.158 31">
      <g transform="translate(0.5 0.5)">
        <g >
          <path d="M27.632,30A27.764,27.764,0,0,1,0,2.368,2.428,2.428,0,0,1,2.368,0H7.895a2.428,2.428,0,0,1,2.368,2.368,20.053,20.053,0,0,0,.789,5.368,2.273,2.273,0,0,1-.632,2.368L8.912,12.792a19.354,19.354,0,0,0,8.526,8.526l2.615-1.9a2.146,2.146,0,0,1,2.368-.474,15.27,15.27,0,0,0,5.368.789,2.428,2.428,0,0,1,2.368,2.368v5.526A2.7,2.7,0,0,1,27.632,30ZM2.368,1.579a.746.746,0,0,0-.789.789A26.078,26.078,0,0,0,27.632,28.421a.746.746,0,0,0,.789-.789V22.105a.746.746,0,0,0-.789-.789,18.119,18.119,0,0,1-5.842-.947.694.694,0,0,0-.789.158L17.912,22.9a.7.7,0,0,1-.789,0,21.023,21.023,0,0,1-9.789-9.789.7.7,0,0,1,0-.789l1.983-3.16a1.16,1.16,0,0,0,.316-.947,18.119,18.119,0,0,1-.947-5.842.746.746,0,0,0-.789-.789Z" fill={props.fill} stroke={props.fill} strokeWidth="1"/>
        </g>
      </g>
    </svg>

    )
}

Phone.defaultProps = {
  fill: "#3dae2b",
  width: 31,
  height: 31
}

export default Phone
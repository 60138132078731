import React, { FunctionComponent } from 'react';

interface Props{
  fill?: string,
  width?: number
  height?: number
}

const SlidingIcon: FunctionComponent<Props> = (props) =>{
  const width = '' + props.width;
  const height = '' + props.height;
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 53.998 28">
        <g data-name="sliding icon" transform="translate(-12848.002 867)">
          <g  data-name="Ellipse 255" transform="translate(12874 -867)" fill="none" stroke={props.fill} strokeWidth="4">
            <circle cx="14" cy="14" r="14" stroke="none"/>
            <circle cx="14" cy="14" r="12" fill="none"/>
          </g>
          <path data-name="Union 13" d="M15819-3294.273l12.726-12.726,2.828,2.827-8.173,8.172H15846v4h-19.069l7.624,7.624-2.828,2.832Z" transform="translate(-2971 2441)" fill={props.fill}/>
        </g>
      </svg>
    )
}

SlidingIcon.defaultProps = {
  fill: "#fff",
  width: 54,
  height:28
}
export default SlidingIcon
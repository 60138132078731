import { rgba } from 'polished';

const brand = {
  primary: '#3DAE2B',
  secondary: '#3DAE2B',
  colors: {
    blue: '#3DAE2B',
    green: '#3DAE2B',
    darkGrey: '#707070',
    beige: '#FFF',
    purple: '#9e1f63',
    orange: '#f3921b',
    white: '#FFFFFF',
    black: '#000000',
    disabled: '#B7B7B7',
    grey: '#F8F8F8',
    midGrey: '#B8B8B8',
    alterGrey: '#EBEBEB',
    error: '#D70826',
    red: '#D53737',
    silver:  "#191919",
    mediumGrey: "#292929",
    lightGreen: "#d8efd5",
    lightToGrey: '#EEEEEE',
    lightGrey: '#707070',
  }
};

const darkTheme = {
  brand,
  breakpoints: {
    // xs: 320,
    // sm: 576,
    mobile: 768,
    tablet: 1024,
    desktop: 1440,
  },
  imageBreakpoints: {
    mobile: 768,
    tablet: 1200,
    desktop: 1920
  },
  responsive: {
    mobile: {
      maxWidth: 768
    },
    tablet: {
      minWidth: 769,
      maxWidth: 1024
    },
    desktop: {
      minWidth: 1025,
      maxWidth: 1200
    },
    widescreen: {
      minWidth: 1201
    }
  },
  responsivePlateDesign: {
    mobile: {
      maxWidth: 768
    },
    tablet: {
      minWidth: 769,
      maxWidth: 1024
    },
    desktop: {
      minWidth: 1025,
      maxWidth: 1439
    },
    widescreen: {
      minWidth: 1440
    }
  },
  colors: {
    body: rgba('#000000', 0.8),
    link: brand.primary,
    linkHover: brand.primary,
    insetBackground: '#fefefe',
    siteBackground: '#fefefe',
    pink: 'pink'
  },
  container: {
    base: '80rem',
    text: '50rem',
  },
  dimensions: {
    banner: 18,
    header: 2,
    footer: 2,
  },
  layers: {
    root: -1,
    base: 1,
    content: 10,
    overlay: 100,
    priority: 1000,
  },
  vehicleTheme: {
    dark: {
      color: brand.colors.darkGrey,
      placeHolderColor: brand.colors.lightGrey,
      bgColor:  brand.colors.silver,
      border: brand.colors.darkGrey,
      shadow: '',
      borderColor: brand.colors.mediumGrey,
    },
    white: {
      color: brand.colors.black,
      bgColor:  brand.colors.white,
      border: brand.colors.black,
      shadow: '0px 0px 4px #fff',
      placeHolderColor: brand.colors.lightGrey,
      borderColor: brand.colors.black
    }
  },
  mobileTheme: {
    color: brand.colors.white,
    secondaryColor: brand.colors.lightGrey,
    bgColor:  brand.colors.black,
    buttonBgColor:  brand.colors.green,
    buttonColor:  brand.colors.black,
    linkColor:  brand.colors.black,
    titleFontSize: "18px"
  },

  desktopTheme: {
    color: brand.colors.white,
    secondaryColor: brand.colors.lightGreen,
    bgColor:  brand.colors.black,
    buttonBgColor:  brand.colors.green,
    buttonColor:  brand.colors.black,
    linkColor:  brand.colors.black,
    titleFontSize: "18px"
  }
};

export default darkTheme;

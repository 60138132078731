import React from 'react'
import LazyLoad  from 'react-lazyload';

interface LazyLoadImageProps{
    src: string
    height?: string | number
    desc?: string
    width?: string | number
    offset?: number
}


const LazyLoadImage = ({src, desc, height, width, offset, ...others}: LazyLoadImageProps) =>{
    const h = height ? height : '100%';
    const alt = desc ? desc : "...";
    const os = offset ? offset : 0;
    const imageSrc = (src.includes("kc-usercontent.com") && width) ?  `${src}?w=${width}` : src;

    return <LazyLoad height={h} offset={os} >
        <img src={imageSrc} alt={alt} {...others}/> 
    </LazyLoad>
}

export default LazyLoadImage;
/** @jsx jsx */
import { css, jsx, Global } from '@emotion/core'; 

import {Container, Image} from './KenticoImage.styles'

declare global {
  interface Document {
      documentMode?: any;
  }
}

if (typeof window !== `undefined`) {
  require('lazysizes/plugins/attrchange/ls.attrchange');
  require('lazysizes/plugins/respimg/ls.respimg');
  require('lazysizes/plugins/object-fit/ls.object-fit');
  require('lazysizes/plugins/parent-fit/ls.parent-fit');
  // Don't include blur-up or lazysizes on IE broswers
  if(!window.document.documentMode){
    require('lazysizes/plugins/blur-up/ls.blur-up');
    require('lazysizes');
  }
}

interface KenticoImageProps{
  className:string, 
  fallback: string, 
  lowSrc:string,
  description?:string,
  sources: JSX.Element[]
}

const KenticoImage = ({ className, fallback, lowSrc, sources, description, ...otherProps }: KenticoImageProps) => (
  <Container>
        <Global
      styles={css`
        .ls-blur-up-img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin-left: auto !important;
          margin-right: auto !important;  
          

          &.ls-inview.ls-original-loaded {
            filter: blur(5px);
            opacity: 0;
            transform: scale(1);
          }
        }
      `}
    />
    <picture>
      {sources}
      <source data-srcset={fallback} data-lowsrc={lowSrc} />
      <Image
        className={`lazyload ${className}`}
        {...otherProps}
        data-lowsrc={lowSrc}
        alt={description ? description : "..."}
        data-sizes="auto"
      />
    </picture>
  </Container>
);


KenticoImage.defaultProps = {
  lowSrc: null,
  alt: 'no image description'
};

export default KenticoImage;
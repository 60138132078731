import React, { FunctionComponent } from 'react';
interface Props{
  fill?: string,
  width?: number
  height?: number
}

const Chat: FunctionComponent<Props> = (props) =>{
  const width = '' + props.width;
  const height = '' + props.height;
    return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 31 27.25">
      <g transform="translate(0.5 0.5)">
        <path data-name="Path 333" d="M2.5,26.25A1.181,1.181,0,0,1,1.25,25a1.777,1.777,0,0,1,.25-.75h0A23.582,23.582,0,0,0,4.875,18.5C1.75,16.5,0,13.625,0,10.625,0,4.75,6.75,0,15,0S30,4.75,30,10.625,23.25,21.25,15,21.25a13.586,13.586,0,0,1-2.25-.125c-3,2.875-8.125,4.5-9.75,5A.752.752,0,0,1,2.5,26.25Zm.125-1.375h0c-.125.125,0,.125,0,0C3.75,24.625,9.125,23,12,20.125a.687.687,0,0,1,.625-.375,16.753,16.753,0,0,0,2.5.125c7.625,0,13.75-4.25,13.75-9.375S22.625,1.25,15,1.25,1.25,5.5,1.25,10.625c0,2.625,1.75,5.25,4.75,7a.955.955,0,0,1,.25.625C6.125,20.125,3.125,24.25,2.625,24.875Z" fill={props.fill} stroke={props.fill} strokeWidth="1"/>
      </g>
    </svg>

    )
}

Chat.defaultProps = {
  fill: "#3dae2b",
  width: 31,
  height: 28
}
export default Chat
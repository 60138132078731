import { predefinedFields } from "@elements/Forms/constants.forms";
import { FormFieldData } from "@elements/Forms/types";
import { PromotionPopupModel, PromotionPopupKenticoModel } from "@models/PromotionPopup/PromotionPopup"
import { PromotionPopupFormKenticoModel, PromotionPopupFormModel } from "@models/PromotionPopupForm/PromotionPopupForm"
import { KenticoChoice } from "@utils/KontentTypes";


const loadSelectedFields = (data: Array<KenticoChoice>) => {
    let fields: FormFieldData[] = [];
    data.forEach(item => {
        if (item.codename === "first_name") {
            fields.push(predefinedFields.firstname_simplify);
        } else if (item.codename === "last_name") {
            fields.push(predefinedFields.lastname_simplify);
        } else if (item.codename === "email") {
            fields.push(predefinedFields.email_simplify);
        }
    });
    return fields;
}

export const mapToPromotionPopups = (kenticoData: PromotionPopupKenticoModel[]): PromotionPopupModel[] => {
    return kenticoData.map((kenticoData: PromotionPopupKenticoModel) => {
        return {
            BackgroundImage: {
                description: kenticoData.elements.background_image.value[0].description,
                url: kenticoData.elements.background_image.value[0].url,
            },
            id: kenticoData.system.name,
            endDate: kenticoData.elements.end_date.value,
            startDate: kenticoData.elements.start_date.value,
            linkUrl: kenticoData.elements.link_url.value,
            allowedURLs: kenticoData.elements.url_s_to_show_on.value.map((url: { codename: string, name: string }) => {
                return url.name
            }),
            promoCode: kenticoData.elements.promo_code.value
        }
    })
}
export const mapToPromotionPopupForms = (kenticoData: PromotionPopupFormKenticoModel[]): PromotionPopupFormModel[] => {
    return kenticoData.map((data: PromotionPopupFormKenticoModel) => {
        var model: PromotionPopupFormModel = {
            name: data.system.name,
            backgroundImage: {
                description: data.elements.background_image.value[0]?.description,
                url: data.elements.background_image.value[0]?.url,
            },
            title: data.elements.competition_title.value,
            subtitle: data.elements.competition_subtitle.value,
            campaignName: data.elements.campaign_name.value,
            startDate: data.elements.start_date.value,
            endDate: data.elements.end_date.value,
            loadTime: data.elements.load_time.value[0].codename,
            termsConditions: data.elements.t_c.value,
            formFields: loadSelectedFields(data.elements.competition_form_fields.value),
            allowedURLs: data.elements.page_to_show_on.value.map((url: { codename: string, name: string }) => {
                return url.name
            }),
            successMessage: data.elements.submit_success_message.value,
            errorMessage: data.elements.submit_error_message.value
        };
        return model;
    })
}
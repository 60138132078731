import React from 'react'
import {
    CardContainer,
    CardIconContainer,
    CardTitle,
    AvatarIcon,
    AvatarImage,
    AvatarIconContainer,
    CardUserRatingContainer,
    CardUser,
    CardRating,
    ReviewContent,
    CardDate} from './CustomerReviewCardSlider.styles'
import {H6, ReviewCardTitle } from "@styles/Global.styles"
import { DisplayRating } from './CustomerReviewCardSlider'
import { Icon } from 'semantic-ui-react'
export interface ReviewCardProps{
    avatarUrl:string 
    username:string 
    rating: number
    title: string 
    content:string
    create_date: string
}

const CustomerReviewCard = ({avatarUrl,username,rating,title,content, create_date}:ReviewCardProps) =>{
    return (
        <CardContainer>
            
                {/*<CardIconContainer>
                 {
                    avatarUrl === undefined ? <AvatarIconContainer><AvatarIcon name='user circle outline'  size="massive" /></AvatarIconContainer> : 
                    <AvatarImage src={avatarUrl} avatar />
                } 
                <Icon name='quote left' size='huge' />
                </CardIconContainer>*/}
                <CardUserRatingContainer>
                    {/* <CardUser><H6>{username}</H6></CardUser> */}
                    <CardRating>
                        {DisplayRating(rating, "#3DAE2B")}
                    </CardRating>
                    {create_date && <CardDate>{create_date}</CardDate>}
                    
                </CardUserRatingContainer> 
                {/*<CardTitle>
                     <ReviewCardTitle>{title}</ReviewCardTitle> 
                </CardTitle>*/}
                <ReviewContent color="#707070">{content}</ReviewContent>
           
            <H6>{username}</H6>
        </CardContainer>
    );
}
export default CustomerReviewCard
import styled from '@emotion/styled';

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const Image = styled.img`
  font-family: 'blur-up: unobtrusive', 'object-fit: cover';
  opacity: 0;
  object-fit: cover;
  transition: opacity cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  &.lazyloaded {
    opacity: 1;
  }
`
import { GridColumn, GridRow,Container, Grid, Segment,List } from "semantic-ui-react";
import styled from "@emotion/styled";
import React from "react";
import theme from "@styles/theme";
import { isCheckout } from "@utils/Helpers";

export const ProgressStatusBarSegment = styled(Segment)`
    padding: 0 !important;
    margin: 0 !important;
`
export const GridColumnItem = styled(GridColumn)`
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 15px;
    line-height: 21px;
    font-weight: bold;
    text-align: center;
    border-right: 1px solid #ccc;
    &:first-of-type{
        border-left: 1px solid #ccc;
    }
    i.green.icon {
        color: ${theme.brand.colors.green} !important;
    }

    i.grey.icon {
        color: ${theme.brand.colors.darkGrey} !important;
    }
`
export const MobileProgressList = styled(List)`
    padding: 10px 0px 8px 0px !important;
    margin: auto !important;
    text-align: center;
    width: 100%;
`
export const MobileProgressTitle = styled.span`
    padding-left: 4px;
`
export const ListItemStep = styled(({isActive, ...others})=><List.Item {...others} />)`
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-right: 3% !important;
    padding-left: 3% !important;
    margin: 0 !important;
    font-size: 15px;
    line-height: 21px;
    font-weight: bold;
    text-align: center;
    border-right: 1px solid #fff;
    &:last-child{
        border-right: 0px solid #fff;
    }
`

export const GridRowBar = styled(GridRow)`
    padding: 10px 0 !important;
`
export const ProgressBarContainer = styled(Container)`
    text-align: center;
`
export const ProgressBarGrid = styled(Grid)`
    margin: 0 !important;
`
export const ProgressBarStrip =styled(({url, ...rest})=><div {...rest}/>)`
    height: 4px;
    background-color: ${props=> isCheckout(props.url) ? theme.brand.colors.midGrey : theme.brand.colors.midGrey};
`
export const ProgressBarStatus = styled(({progress, url, ...rest})=><div {...rest}/>)`
    background-color: ${props=> isCheckout(props.url) ? theme.brand.colors.darkGrey : theme.brand.colors.midGrey};
    width: ${props=>props.progress}%;
    height: 100%;
`
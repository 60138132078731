import { KpFormikEmailFieldProps } from "./types";
import React from "react";
import { FormikFieldEmailValidationInput, KpFormField, KpFormInputErrorMessage } from "@styles/Global.forms";
import { SignupEmailValidatingLoader } from "@components/PersonalDetailsForm/PersonalDetailsForm.styles";

var get = require('lodash/get');

export const KpEmailFormikField = (props: KpFormikEmailFieldProps) =>{
    const {data, errors,touched,handleChange,handleBlur,values,status,validateEmailAddress, validatingEmail, emailIsValid} = props;
    return (
        <KpFormField required={data.isRequired} error={get(errors,data.id) && get(touched, data.id)}>
        {
            data.label && 
            <label>{data.label}</label>
        }
        {
        get(errors,data.id) && get(touched, data.id) && (
        <KpFormInputErrorMessage>{get(errors, data.id)}</KpFormInputErrorMessage>
        )}
        <FormikFieldEmailValidationInput
            id={data.id}
            name={data.name}
            type={data.type}
            data-private="lipsum"
            aria-label={data.name}
            disabled={status.isSubmitting}
            inputValid={emailIsValid}
            placeholder={data.placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
            validate={validateEmailAddress}
            value={get(values, data.id)} />
            {
                validatingEmail && 
                <SignupEmailValidatingLoader active inline size="small" />
            }
    </KpFormField>
    );
}

import React, { FunctionComponent } from 'react';
interface Props{
  reverse?: boolean
  width?: number
  height?: number,
  black?: boolean
}

const Logo: FunctionComponent<Props> = (props) =>{
  const width = '' + props.width;
  const height = '' + props.height;

  let background = "#fff";
  let fill = props.black ? "#000" : "#3dae2b";
  if(props.reverse){
    background = fill;
    fill = "#fff";
  }
    return (
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 356.63 105.56">
  <g>
    <path fill={background} d="M330.97,94.59l-319.3,8.14c-3.69,0.09-6.76-2.82-6.85-6.51L2.83,18.25c-0.09-3.69,2.82-6.76,6.51-6.85
      l319.3-8.14c3.69-0.09,6.76,2.82,6.85,6.51l1.99,77.97C337.58,91.43,334.66,94.49,330.97,94.59z"/>
  </g>
  <g>
    <g>
      <g>
        <path fill={fill} d="M48.14,77.81l-14.6-19.99v19.99H21.76V29.3h11.78v20.83l14.9-20.83H61.3L44.34,53.4l17.49,24.4H48.14z"/>
      </g>
      <g>
        <path fill={fill} d="M64.51,77.81V43.37h11.25v34.44H64.51z"/>
      </g>
      <g>
        <path fill={fill} d="M119.34,78.11h-7.6l-4.56-13.61c-0.76-2.13-1.44-4.41-2.05-6.99h-0.15c-0.61,2.66-1.29,4.94-1.98,6.92
          l-4.56,13.68h-7.68L78.75,43.37h10.19l4.26,13c0.61,1.98,1.29,4.26,1.67,6.46h0.15c0.53-2.13,1.14-4.56,1.75-6.46l4.26-13h8.44
          l4.33,12.92c0.68,2.13,1.22,4.41,1.67,6.54h0.15c0.46-2.2,1.06-4.56,1.75-6.54l4.26-12.92h9.73L119.34,78.11z"/>
      </g>
      <g>
        <path fill={fill} d="M134.27,77.81V43.37h11.25v34.44H134.27z"/>
      </g>
      <g>
        <path fill={fill} d="M199.84,79.33c-4.64-0.3-10.34-4.49-10.34-12.47V23.68h11.02v42.35c0,3.27,1.98,5.02,4.26,5.55L199.84,79.33
          z"/>
      </g>
      <g>
        <path fill={fill} d="M236.18,78.87c-3.34-0.23-5.85-1.52-7.3-3.88c-2.36,2.58-5.93,3.88-9.65,3.88
          c-6.99,0-12.47-4.26-12.47-11.33c0-6.31,4.94-10.49,11.71-11.18l6.84-0.68v-1.22c0-2.13-1.52-3.57-5.4-3.57
          c-3.95,0-7.91,1.06-10.11,2.36l-0.84-8.51c2.58-1.44,7.3-2.43,11.56-2.43c9.58,0,15.58,4.94,15.58,13.84v9.88
          c0,3.04,1.52,4.71,3.95,5.09L236.18,78.87z M225.61,62.76l-4.64,0.46c-3.19,0.3-4.49,2.36-4.49,4.49c0,1.82,1.52,3.35,3.65,3.35
          c2.74,0,5.47-2.21,5.47-5.09V62.76z"/>
      </g>
      <g>
        <path fill={fill} d="M264.57,52.49h-11.58v12.47c1.77,2.58,3.52,4.03,6.03,4.03c1.44,0,2.58-0.15,3.57-0.53l-0.84,9.12
          c-1.14,0.53-3.04,0.84-5.25,0.84c-7.6,0-12.77-4.94-12.77-11.86V52.49h-5.85v-9.12h6.08v-6.69l10.64-4.03v10.72h10.8
          L264.57,52.49z"/>
      </g>
      <g>
        <path fill={fill} d="M298.96,59.41l-4.49,4.41h-17.18c0.84,3.04,4.03,6.54,10.11,6.54c3.95,0,6.99-0.91,9.96-2.66l-0.99,9.2
          c-2.36,1.14-5.7,1.98-10.26,1.98c-11.25,0-20.15-7.22-20.15-18.47c0-11.18,8.29-18.09,17.94-18.09c9.58,0,15.43,6.61,15.43,13.68
          C299.34,57.13,299.19,58.42,298.96,59.41z M283.91,50.74c-3.35,0-6.23,2.43-6.99,5.93h12.16c0.08-0.23,0.08-0.46,0.08-0.76
          C289.15,53.18,287.1,50.74,283.91,50.74z"/>
      </g>
      <g>
        <path fill={fill} d="M312.99,78.87c-4.71,0-8.82-1.37-11.1-2.89l-0.99-9.73c2.89,2.51,7.3,4.26,11.63,4.26
          c2.28,0,3.65-1.06,3.65-2.51c0-1.67-1.29-2.43-3.95-3.42c-6.31-2.21-10.19-6.01-10.19-11.4c0-6.39,5.17-10.87,12.7-10.87
          c4.26,0,7.83,1.22,9.43,2.2l-0.84,8.44c-1.75-1.22-4.56-2.36-7.91-2.36c-1.98,0-3.19,0.99-3.19,2.43c0,1.44,1.06,2.2,4.11,3.19
          c6.69,2.13,10.26,6.01,10.26,11.63C326.6,73.78,322.34,78.87,312.99,78.87z"/>
      </g>
    </g>
    <g>
      <g>
        <path fill={fill} d="M170.68,58.64h-6.66V78.3h-11.67V28.32h18.33c8.62,0,16.14,5.72,16.14,15.12
          C186.82,52.84,179.3,58.64,170.68,58.64z M168.96,37.88h-4.93v11.2h4.93c3.13,0,6.27-1.72,6.27-5.64
          C175.23,39.68,172.09,37.88,168.96,37.88z"/>
      </g>
    </g>
    <g>
      <circle fill={fill} cx="70.14" cy="32.65" r="5.41"/>
    </g>
    <g>
      <circle fill={fill} cx="139.9" cy="32.65" r="5.41"/>
    </g>
  </g>
  <g>
    <path fill={background} d="M339.29,7.33c0-2.44,2.05-4.52,4.42-4.52c2.61,0,4.55,2.11,4.55,4.59c0,2.66-2.12,4.59-4.48,4.59
      C341.21,11.99,339.29,9.89,339.29,7.33z M347.28,7.46c0-2.13-1.57-3.73-3.47-3.73c-1.93,0-3.55,1.56-3.55,3.67
      c0,2.05,1.6,3.67,3.51,3.67C345.72,11.07,347.28,9.41,347.28,7.46z M344.64,9.9c-0.04-0.08-0.08-0.28-0.11-0.53l-0.05-0.62
      c-0.05-0.55-0.3-0.81-0.87-0.81h-0.53V9.9h-1.24V4.8h2.13c1.07,0,1.8,0.51,1.8,1.44c0,0.59-0.29,0.99-0.81,1.23
      c0.47,0.19,0.7,0.55,0.74,1.46c0.01,0.46,0.05,0.78,0.13,0.97H344.64z M343.74,7.11c0.5,0,0.81-0.22,0.81-0.78
      c0-0.44-0.27-0.66-0.74-0.66h-0.71v1.44H343.74z"/>
  </g>
</svg>

    )
}

Logo.defaultProps = {
  reverse: false,
  width: 160,
  height: 45
}
export default Logo
import React, { Fragment } from "react"
import { Link, navigate } from "gatsby"
import {
  ShoppingCartContainer,
  NavigationMenuLink,
  NavigationMenuDropdown,
} from "@elements/index"
import { Icon, List } from "semantic-ui-react"
import {
  LogoIcon,
  HeaderSegment,
  SimpleHeaderSegment,
  SimpleHeaderContainer,
  SimpleTopNavContainer,
  SimpleTopNavMessage,
  SimpleTopNavAuth,
  PhoneLink,
  MessageContainer,
  DesktopPhoneLinkWrapper,
  DesktopSingoutLinkWrapper,
  MessageContainerLink,
  SimpleShoppingCartContainer,
} from "./Header.styles"

import { Container } from "semantic-ui-react"
import { NavItem, TopNavMenuResult } from "@components/Layout/mapper"
import { ProgressStatusBar } from "@components/ProgressStatusBar"
import { PhoneNavigation } from "@assets/svgs"
import { Record } from "immutable"
import { currentBasketSelector } from "@redux/selectors/basketSelector"
import { BasketLineResponseModel } from "@models/Basket/BasketLineResponseModel"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { NavigationMenuButton } from "@elements/NavigationMenuLink/NavigationMenuLink.styles"
import theme from "@styles/theme"
import UserLink from "@elements/UserLink/UserLink"
import { getCurrentCheckout, getEmptyCheckout } from "@redux/localStorage/checkout"
import { Address, Checkout } from "@models/Checkout/Checkout"
import { updateDealerAddress, updateDeliveryAddress, updateIsAcceptStatement, updateIsDealerOrder, updatePersonalDetails } from "@redux/actions/checkout"
import { render } from "react-dom"

interface DesktopPageHeaderProps {
  apiUrl: string
  topNavMenu: NavItem[]
  version: string
  currentStep?: number
  cartLineItems: BasketLineResponseModel[]  
  updatePersonalDetails: (personalDetails: Checkout) => void
  updateDeliveryAddress: (deliveryAddress: Address) => void
  updateDealerAddress: (dealerAddress: Address) => void
  updateIsDealerOrder: (isDealerOrder: boolean) => void
  updateIsAcceptStatement: (isAcceptStatement: boolean) => void
}

class DesktopPageHeaderContainer extends React.Component<
  DesktopPageHeaderProps
  > {
  constructor(props: DesktopPageHeaderProps) {
    super(props)
  }
  componentDidMount() {
    if (
      this.props.version === "simple" &&
      this.props.currentStep &&
      this.props.currentStep > 2 &&
      this.props.currentStep <= 4 &&
      this.props.cartLineItems.length < 1
    ) {
      navigate("/shopping-cart/")
    }
  }
  render() {
    const { version, currentStep, apiUrl, topNavMenu } = this.props
    let isDealerOrder = false;
    if(getCurrentCheckout() && getCurrentCheckout().isDealerOrder){
      isDealerOrder = getCurrentCheckout().isDealerOrder
    }
    
    return (
      <Fragment>
        {(version === "simple" || version === 'darkSimple') && (
          <Fragment>
            <SimpleHeaderSegment basic>
              <SimpleHeaderContainer>
                <Link to={isDealerOrder ? "/dealer-new-order" : "/"} aria-label="Homepage">
                  <LogoIcon reverse={true}/>
                </Link>
                <SimpleTopNav apiUrl={apiUrl} 
                updatePersonalDetails={this.props.updatePersonalDetails}
                updateDeliveryAddress={this.props.updateDeliveryAddress}
                updateDealerAddress={this.props.updateDealerAddress}
                updateIsDealerOrder={this.props.updateIsDealerOrder}
                updateIsAcceptStatement={this.props.updateIsAcceptStatement}/>
              </SimpleHeaderContainer>
            </SimpleHeaderSegment>
            {/* {currentStep !== undefined && (
              <ProgressStatusBar currentStep={currentStep} isMobile={false} />
            )} */}
          </Fragment>
        )}
        {version === "authSimple" && (
          <Fragment>
            <SimpleHeaderSegment basic>
              <SimpleHeaderContainer>
                <Link to="/" aria-label="Homepage">
                  <LogoIcon reverse={true}/>
                </Link>
                <TopNavMenu topNavMenu={topNavMenu} apiUrl={apiUrl} darkTheme={version === "dark" ? true : false}/>
              </SimpleHeaderContainer>
            </SimpleHeaderSegment>
          </Fragment>
        )}
        {(version === "standard" || version === "blank" || version === "dark") && (
          <HeaderSegment basic>
            <Container>
              <Link to={isDealerOrder ? "/dealer-new-order" : "/"} aria-label="Homepage">
                <LogoIcon reverse={true}/>
              </Link>
              <TopNavMenu topNavMenu={topNavMenu} apiUrl={apiUrl} darkTheme={version === "dark" ? true : false} />
            </Container>
          </HeaderSegment>
        )}       
        {version === "dealer" && (
          <HeaderSegment basic>
            <SimpleHeaderContainer>
                <Link to="/dealer-new-order" aria-label="Homepage">
                  <LogoIcon reverse={true} />
                </Link>
                <DealerTopNav apiUrl={apiUrl} />
              </SimpleHeaderContainer>
          </HeaderSegment>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state: Record<any>) => {
  const cart = currentBasketSelector(state).toJS()
  return {
    cartLineItems: cart.basketLines,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updatePersonalDetails(personalDetails: Checkout) {    
    dispatch(updatePersonalDetails(personalDetails))
  },
  updateIsDealerOrder(isDealerOrder: boolean){
    dispatch(updateIsDealerOrder(isDealerOrder))
  },
  updateDeliveryAddress(deliveryAddress: Address) {
    dispatch(updateDeliveryAddress(deliveryAddress));
  },
  updateDealerAddress(dealerAddress: Address){
    dispatch(updateDealerAddress(dealerAddress))
  },
  updateIsAcceptStatement(isAcceptStatement: boolean) {
    dispatch(updateIsAcceptStatement(isAcceptStatement));
  }
})
const DesktopPageHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(DesktopPageHeaderContainer)
export default DesktopPageHeader

interface TopNavMenuProps {
  topNavMenu: NavItem[]
  apiUrl: string
  darkTheme : boolean
}

export const isMarketplacePages = () => {
  const url = typeof window !== "undefined" ? window.location.href : ""
  const marketplaceUrlsKeywords = [
    "marketplace",
    "listing-detail",
    "report-listing",
    "login",
    "app/",
  ]
  let isMarketplacePage = false
  for (let keyword of marketplaceUrlsKeywords) {
    if (url.includes(keyword)) {
      isMarketplacePage = true
      break
    }
  }
  return isMarketplacePage
}
const TopNavMenu = ({ topNavMenu, apiUrl, darkTheme }: TopNavMenuProps) => {
  const isCurrentPageMarketplacePage = isMarketplacePages()
  
  return (
    <List horizontal floated="right">
      {topNavMenu.sort((a, b) => a.order - b.order).map((navmenu, i) => {
        const { title, url, sub_nav } = navmenu
        return sub_nav.length !== 0 ? (
          <NavigationMenuDropdown key={i} text={title} linkList={sub_nav} />
        ) : (
            <NavigationMenuLink key={i} text={title} linkUrl={url} />
          )
      })}

      <NavigationMenuButton
        ignoreMinWidth={true}
        id="create_nav_item"
        link="/create/"
        buttonType="primary"
        textColor="black"
        color="#3DAE2B"
        darkTheme={darkTheme}
      >
        CREATE A PLATE
      </NavigationMenuButton>
      {isCurrentPageMarketplacePage && <UserLink apiUrl={apiUrl} />}
      <ShoppingCartContainer
        fill={theme.brand.colors.white}
        isSimple={true}
        apiUrl={apiUrl}
      />
    </List>
  )
}

interface SimpleTopNavProps {
  apiUrl: string
  updatePersonalDetails: (personalDetails: Checkout) => void
  updateDeliveryAddress: (deliveryAddress: Address) => void
  updateDealerAddress: (dealerAddress: Address) => void
  updateIsDealerOrder: (isDealerOrder: boolean) => void
  updateIsAcceptStatement: (isAcceptStatement: boolean) => void
}

class SimpleTopNav extends React.Component<SimpleTopNavProps> {
  signOut = () => {       
    const emptyCheckout = getEmptyCheckout()
    this.props.updatePersonalDetails(emptyCheckout)
    this.props.updateIsDealerOrder(false)
    this.props.updateDealerAddress(emptyCheckout.dealerAddress)
    this.props.updateDeliveryAddress(emptyCheckout.deliveryAddress)
    this.props.updateIsAcceptStatement(emptyCheckout.isAcceptStatement)
    navigate("/dealer-login")    
  }

  render() {
    const checkout = getCurrentCheckout()
    return (
      <SimpleTopNavContainer>      
        {checkout.isDealerOrder? 
        <>
          <SimpleTopNavAuth onClick={this.signOut}>
            <MessageContainer>
              Dealer Logout          
            </MessageContainer>
            <DesktopSingoutLinkWrapper>
              <Icon name="sign-out" size="big" />
            </DesktopSingoutLinkWrapper>
          </SimpleTopNavAuth>
          <SimpleShoppingCartContainer>
            <ShoppingCartContainer
              fill={theme.brand.colors.white}
              isSimple={true}
              apiUrl={this.props.apiUrl}
            />
          </SimpleShoppingCartContainer>
        </>
        :
        <>
          <SimpleTopNavMessage>
            <MessageContainer>
              Need any help? Call us on{" "}
              <MessageContainerLink href="tel:0800650111">
                0800 650 111
              </MessageContainerLink>
            </MessageContainer>
            <DesktopPhoneLinkWrapper>
              <PhoneLink href="tel:0800650111">
                <PhoneNavigation />
              </PhoneLink>
            </DesktopPhoneLinkWrapper>
          </SimpleTopNavMessage>     
          <SimpleShoppingCartContainer>
            <ShoppingCartContainer
              fill={theme.brand.colors.white}
              isSimple={true}
              apiUrl={this.props.apiUrl}
            />
          </SimpleShoppingCartContainer>
        </>
        }     
      </SimpleTopNavContainer>
    )
  }
  
}

const DealerTopNav = ({ apiUrl }: { apiUrl: string }) => {
  
  return (
    <SimpleTopNavContainer>
      
        <SimpleShoppingCartContainer>
          <ShoppingCartContainer
            fill={theme.brand.colors.white}
            isSimple={true}
            apiUrl={apiUrl}
          />
        </SimpleShoppingCartContainer>
    </SimpleTopNavContainer>
  )
}
import { FeatureFlag } from '@utils/Constant';
import Axios from 'axios'

const ValidEmailResult = [
    'verified',
    'unknown',
]
const ValidEamilResultIncludeRoleAccounts = [
    'illegitimate',
    'verified',
    'unknown',
]

const checkValidationResult = (result: any, allowRoleAccounts: boolean) =>{
    let checkResult = false;
    const confidence = result.confidence;
    const roleAccountVerbose = "roleAccount";
    if(confidence == 'illegitimate' && result.verbose == roleAccountVerbose && allowRoleAccounts){
        checkResult = true;
    }else if(ValidEmailResult.includes(confidence)){
        checkResult = true;
    }
    return checkResult;
}

const validate = async (token:string, email: string, allowRoleAccounts: boolean = false) =>{

    var isEmail = String(email)
                    .toLowerCase()
                    .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
    if (isEmail == null) {
        return {
            success: false,
            message: "Invalid Email Address format."
        }
    }else{
        if(FeatureFlag.EnableEmailValidation){
            if(token == undefined || token == null){
                console.log('ERROR: Failed to load email validation service token!');
                
                return {
                    success: false,
                    message: "Failed to validate Email."
                }
            }
            const headers = {
                'Auth-Token': token,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Reference-Id': 'kiwiplates-web'
            };
            const data = {
                "email": email,
                "timeout": 5
              }
            const url = `https://api.experianaperture.io/email/validation/v1`;
            try{
                const result = await Axios.post(url,data,{headers: headers});
                const resultData = result.data;
                if(result.status == 200){
                    const confidence = resultData.result.confidence;
                    if(checkValidationResult(resultData.result, allowRoleAccounts)){
                        return {
                            success:true,
                            message: confidence
                        }
                    }else{
                        return {
                            success: false,
                            message: confidence
                        }
                    }
                }
            }catch(error){
                if(error)
                    console.log(error)
                return {
                    success: true,
                    message: 'timeout'
                }
            }
        }else{
            return {
                success: true,
                message: ""
            }
        }
    }

}


export const EmailValidationService = {
    validate:validate,
}
import React, { FunctionComponent } from 'react';

interface Props{
  fill?: string,
  width?: number
  height?: number
}

const Trailer: FunctionComponent<Props> = (props) =>{
  const width = '' + props.width;
  const height = '' + props.height;
    return (
<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 9.91">
  <g transform="translate(-137.013 -3900.209)">
    <path data-name="Path 381" d="M140.036,3901.715h-1a.5.5,0,1,0,0,1h1a.5.5,0,1,0,0-1Z" transform="translate(0.001 0.001)" fill={props.fill}/>
    <path data-name="Path 382" d="M151.775,3902.715h1a.5.5,0,1,0,0-1h-1a.5.5,0,1,0,0,1Z" transform="translate(0.008 0.001)" fill={props.fill}/>
    <path data-name="Path 383" d="M160.513,3905.3a.5.5,0,0,0-.5.5v.313h-5.164v-5.408a.5.5,0,0,0-.5-.5H137.513a.5.5,0,0,0-.5.5v5.908a.5.5,0,0,0,.5.5h3.768a3,3,0,0,0,6,0h12.847a.494.494,0,0,0,.381.187.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,160.513,3905.3Zm-22.5-4.094h15.835v4.908H147.1a2.983,2.983,0,0,0-5.634,0h-3.452Zm6.269,7.909a2,2,0,1,1,2-2A2,2,0,0,1,144.283,3909.119Z" transform="translate(0 0)" fill={props.fill}/>
  </g>
</svg>
    )
}

Trailer.defaultProps = {
  fill: "#707070",
  width: 24,
  height: 10
}
export default Trailer
import React, { Fragment } from "react"
import { Divider, Popup } from "semantic-ui-react"

import {
  PopupDetailWrapper,
  UserIcon,
  UserIconWrapper,
} from "./UserLink.styles"
import {
  DropdownItem,
  DropdownListItemMessage,
  DropdownListItemTitle,
  NavListItem,
  NavMenuLinkDropdownItemLink,
  NavMenuLinkDropdownMenu,
  UserAuthDropdownItem,
  UserAuthDropdownItemInfo,
} from "@elements/NavigationMenuDropdown/NavigationMenuDropdown.styles"
import { isLoggedIn } from "@auth/auth"
import { authContext } from "@auth/AuthContext"
import Auth, { CognitoUser } from "@aws-amplify/auth"
import { navigate } from "gatsby"

interface UserPopupProps {
  apiUrl: string
}

const UserPopup = (props: UserPopupProps) => {
  const { auth } = React.useContext(authContext)
  const [username, setUsername] = React.useState("")
  auth.user?.getUserAttributes((err, result) => {
    if (result !== undefined) {
      const username = result.find(r => r.Name == "name")?.Value
      if (username) setUsername(username)
    }
  })
  return (
    <Fragment>
      {auth.state == "signIn" && auth.user != null ? (
        <LoggedInUserPopup username={username} />
      ) : (
        <AnonymousUserPopup />
      )}
    </Fragment>
  )
}

const AnonymousUserPopup = () => {
  return (
    <NavMenuLinkDropdownMenu>
      <UserAuthDropdownItem>
        <NavMenuLinkDropdownItemLink>
          <DropdownListItemTitle>My Account</DropdownListItemTitle>
        </NavMenuLinkDropdownItemLink>
      </UserAuthDropdownItem>
      <Divider />
      <UserAuthDropdownItem>
        <NavMenuLinkDropdownItemLink url="/signup">
          <DropdownListItemTitle>Create an Account</DropdownListItemTitle>
        </NavMenuLinkDropdownItemLink>
      </UserAuthDropdownItem>
      <UserAuthDropdownItem>
        <NavMenuLinkDropdownItemLink url="/login">
          <DropdownListItemTitle>Log In</DropdownListItemTitle>
        </NavMenuLinkDropdownItemLink>
      </UserAuthDropdownItem>
    </NavMenuLinkDropdownMenu>
  )
}

const LoggedInUserPopup = (props: { username: string }) => {
  const signOut = () => {
    Auth.signOut().then(res => {
      // console.log(res)
      navigate("/login")
    })
  }
  return (
    <NavMenuLinkDropdownMenu>
      <UserAuthDropdownItemInfo>
        <NavMenuLinkDropdownItemLink>
          <DropdownListItemMessage>
            Hi, {props.username}
          </DropdownListItemMessage>
        </NavMenuLinkDropdownItemLink>
      </UserAuthDropdownItemInfo>
      <Divider />
      <UserAuthDropdownItem>
        <NavMenuLinkDropdownItemLink url="/app/listings">
          <DropdownListItemTitle>My Orders</DropdownListItemTitle>
        </NavMenuLinkDropdownItemLink>
      </UserAuthDropdownItem>
      <UserAuthDropdownItem>
        <NavMenuLinkDropdownItemLink url="/app/profile">
          <DropdownListItemTitle>My Details</DropdownListItemTitle>
        </NavMenuLinkDropdownItemLink>
      </UserAuthDropdownItem>
      <UserAuthDropdownItem onClick={signOut}>
        <DropdownListItemTitle>Sign Out</DropdownListItemTitle>
      </UserAuthDropdownItem>
    </NavMenuLinkDropdownMenu>
  )
}

const UserPopupDetail = (props: UserPopupProps) => {
  const apiUrl = props.apiUrl
  return <PopupDetailWrapper></PopupDetailWrapper>
}

interface UserLinkProps {
  isSimple?: boolean
  apiUrl: string
}

const UserLink = (props: UserLinkProps) => {
  return (
    <Popup
      trigger={
        <UserIconWrapper>
          <UserIcon name="user circle outline" size="huge" />
        </UserIconWrapper>
      }
      flowing
      on="click"
      position="bottom right"
    >
      <UserPopup apiUrl={props.apiUrl} />
    </Popup>
  )
}

export default UserLink

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import "semantic-ui-less/semantic.less"
import ThemeWrapper from './ThemeWrapper'
import DesktopLayoutContainer from './DesktopLayoutContainer'
import MobileLayoutContainer from './MobileLayoutContainer'
import { PromotionPopupKenticoModel } from '@models/PromotionPopup/PromotionPopup'
import { PromotionPopupFormKenticoModel } from '@models/PromotionPopupForm/PromotionPopupForm'
import { debounce } from "lodash"
import AuthProvider from '@auth/AuthContext'
export const currentDateTime: Date = new Date();

declare global {
  interface Window { ab_branch: any; }
}
export const showMessage = (startDate: string | undefined, endDate: string | null) => {
  if (endDate === null) {
    return true;
  } else {
    const ed = new Date(endDate);
    if (ed >= currentDateTime) {
      if (startDate !== undefined) {
        const sd = new Date(startDate);
        return currentDateTime >= sd;
      }
      return true;
    } else {
      return false;
    }
  }
}
export const showPopupOnPage = (allowedUrls: string[]) => {
  if (typeof window !== 'undefined') {
    const allowed = allowedUrls.find((url: string) => {
      return url.toLowerCase().replaceAll('/', '') === window.location.pathname.toLowerCase().replaceAll('/', '');
    });
    return allowed !== undefined;
  } else {
    return false;
  }
}
export const showMessageBanner = (key: string) => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(key) === null ? true : false;
  } else {
    return false;
  }
}

// const ResponsiveContainer = (props:any) => {
//     return (
//             <ThemeWrapper>
//                 <DesktopLayoutContainer {...props} >{props.children}</DesktopLayoutContainer>
//                 <MobileLayoutContainer {...props} >{props.children}</MobileLayoutContainer>
//             </ThemeWrapper>);
// }

interface ResponsiveLayoutContainerProps {
  children: any
  apiUrl: string
  emailValidationToken: string
  siteBuildInfo: any
  navItems: any
  announcementItems: any
  promotionPopupItems: PromotionPopupKenticoModel[]
  promotionPopupForms: PromotionPopupFormKenticoModel[]
  version: any
  currentStep?: any
}
interface ResponsiveLayoutContainerStates {
  width: number,
  showPopupOnClose: boolean
}
let lastScrollY = 0;
class ResponsiveLayoutContainer extends React.Component<ResponsiveLayoutContainerProps, ResponsiveLayoutContainerStates>{

  constructor(props: ResponsiveLayoutContainerProps) {
    super(props)
    this.state = {
      width: 768,
      showPopupOnClose: false
    }
  }


  handleMouseMove = (e: { clientX: any; clientY: any }) => {
    var mx = e.clientX;
    var my = e.clientY;
    if (my < 5) {
      this.setState({ showPopupOnClose: true });
    }
  }
  handleScroll = debounce(() => {
    let currentScrollY = window.scrollY;
    if (currentScrollY < lastScrollY - 5) {
      //scrolling up
      lastScrollY = currentScrollY;
      this.setState({ showPopupOnClose: true });
    } else {
      lastScrollY = currentScrollY;
    }
  }, 200);
  componentDidMount() {
    this.handleWindowSizeChange() // Set width
    window.addEventListener('resize', this.handleWindowSizeChange)
    window.addEventListener('mousemove', this.handleMouseMove)
    window.addEventListener('scroll', this.handleScroll, true);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);

    window.removeEventListener('mousemove', this.handleMouseMove)
    window.removeEventListener('scroll', this.handleScroll);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth })
  }
  render() {
    return (
      <ThemeWrapper themeName="dark">
        <DesktopLayoutContainer {...this.props} windowWidth={this.state.width} beforeCloseFired={this.state.showPopupOnClose} >{this.props.children}</DesktopLayoutContainer>
        <MobileLayoutContainer {...this.props} windowWidth={this.state.width} beforeCloseFired={this.state.showPopupOnClose}>{this.props.children}</MobileLayoutContainer>
      </ThemeWrapper>
    )
  }
}

const Layout = ({ version, currentStep, authEnabled, children }: { version: string, currentStep?: number, authEnabled?: boolean, children: any }) => (
  <StaticQuery
    query={
      graphql`{
              site {
                siteMetadata {
                  branch
                  environment
                  branch
                  lastBuilt
                  apiUrl
                  emailValidationToken
                }
              }  
              allKontentItemCompetitionPopupForm{
                nodes {
                  system {
                    name
                  }
                  elements {
                    campaign_name {
                      value
                    }
                    load_time{
                      value{
                        codename
                        name
                      }
                    }
                    competition_title {
                      value
                    }
                    competition_subtitle {
                      value
                    }
                    background_image {
                      value {
                        description
                        url
                      }
                    }
                    competition_form_fields {
                      value {
                        codename
                        name
                      }
                      name
                    }
                    t_c {
                      value
                    }
                    start_date{
                      value
                    }
                    end_date{
                      value
                    }         
                    page_to_show_on{
                      value{
                        codename
                        name
                      }
                    }        
                    submit_success_message{
                      value
                    }
                    submit_error_message{
                      value
                    }
                  }
                }
              }  
              allKontentItemPromotionPopup {
                nodes {
                  system {
                    name
                  }
                  elements {
                    background_image {
                      value {
                        description
                        url
                        name
                      }
                    }
                    end_date {
                      value(formatString: "")
                    }
                    link_url {
                      value
                    }
                    start_date {
                      value(formatString: "")
                    }
                    url_s_to_show_on {
                      value {
                        codename
                        name
                      }
                    }
                    promo_code{
                      value
                    }
                  }
                }
              }
              allKontentItemAnnouncement(filter: {elements: {show___hide: {value: {elemMatch: {codename: {eq: "show"}}}}}}) {
                nodes {
                  elements {
                    title__desktop___tablet_ {
                      value
                    }
                    title__mobile_ {
                      value
                    }
                    text_colors {
                      value {
                        name
                        codename
                      }
                    }
                    background_colors{
                      value{
                        name
                        codename
                      }
                    }
                    expired_date {
                      value
                    }
                  }
                }
              }
              allKontentItemNavigationItemV2 {
                nodes {
                  elements {
                    title {
                      value
                    }
                    order {
                      value
                    }
                    url {
                      value
                    }
                    sub_nav {
                      linked_items {
                        ... on KontentItemNavigationItemV2 {
                          elements {
                            url {
                              value
                            }
                            title {
                              value
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            
            `
    }
    render={(data) => {
      if (typeof window !== `undefined`) {
        // For split testing, we set this so google tag manager has access to the branch used for deployment
        window.ab_branch = data.site.siteMetadata.branch;
      }
      return (
        <>
          {
            authEnabled ?
              <AuthProvider>
                <ResponsiveLayoutContainer
                  apiUrl={data.site.siteMetadata.apiUrl}
                  emailValidationToken={data.site.siteMetadata.emailValidationToken}
                  siteBuildInfo={data.site.siteMetadata}
                  navItems={data.allKontentItemNavigationItemV2.nodes}
                  announcementItems={data.allKontentItemAnnouncement.nodes}
                  promotionPopupItems={data.allKontentItemPromotionPopup.nodes}
                  promotionPopupForms={data.allKontentItemCompetitionPopupForm.nodes}
                  version={version}
                  currentStep={currentStep} >
                  {children}
                </ResponsiveLayoutContainer>
              </AuthProvider>
              :
              <ResponsiveLayoutContainer
                apiUrl={data.site.siteMetadata.apiUrl}
                emailValidationToken={data.site.siteMetadata.emailValidationToken}
                siteBuildInfo={data.site.siteMetadata}
                navItems={data.allKontentItemNavigationItemV2.nodes}
                announcementItems={data.allKontentItemAnnouncement.nodes}
                promotionPopupItems={data.allKontentItemPromotionPopup.nodes}
                promotionPopupForms={data.allKontentItemCompetitionPopupForm.nodes}
                version={version}
                currentStep={currentStep} >
                {children}
              </ResponsiveLayoutContainer>
          }
        </>);
    }}></StaticQuery>
)
Layout.defaultProps = {
  version: "standard",
  location: "/"
}
export default Layout;

import React, { FunctionComponent } from 'react';


interface Props{
  fill?: string,
  width?: number
  height?: number
}

const Facebook: FunctionComponent<Props> = (props) =>{
  const width = '' + props.width;
  const height = '' + props.height;
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 30">
        <g transform="translate(0 0.001)">
          <path data-name="Subtraction 1" d="M11558-2959a14.9,14.9,0,0,1-10.606-4.393A14.9,14.9,0,0,1,11543-2974a14.906,14.906,0,0,1,4.393-10.607A14.9,14.9,0,0,1,11558-2989a14.9,14.9,0,0,1,10.608,4.395A14.909,14.909,0,0,1,11573-2974a14.9,14.9,0,0,1-4.393,10.605A14.9,14.9,0,0,1,11558-2959Zm-2.96-14.412v9.805h4.055v-9.805h2.678l.354-3.442h-3.032v-2.03a.829.829,0,0,1,.862-.938h2.179v-3.346l-3-.011a3.8,3.8,0,0,0-4.092,4.092v2.228h-1.929v3.448Z" transform="translate(-11543 2989)" fill={props.fill}/>
        </g>
      </svg>
    )
}

Facebook.defaultProps = {
  fill: "#fff",
  width: 30,
  height: 30
}

export default Facebook
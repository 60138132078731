import React from 'react'
import styled from '@emotion/styled';
import {Image, Grid, Container} from 'semantic-ui-react'
import {H6, H3} from '@styles/Global.styles';
import mq from '@styles/mq';
import theme from '@styles/theme';
import darkTheme from '@styles/dark-theme';
export const BackLinkContainer = styled.div`
    ${mq({
        marginTop: ["20px","20px","0px"]
    })}
    margin-bottom: 40px;
`
export const BackLink = styled.span`
    color: ${theme.brand.colors.darkGrey};
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
`
export const PlateImageGridColumn = styled(({darkTheme, ...rest}) => (<Grid.Column {...rest}/>))`
    background-color: ${p => p.darkTheme ? theme.brand.colors.black : theme.brand.colors.grey};
    color: ${p => p.darkTheme ? 'white' : theme.brand.colors.black};
`
export const ImageContainerColumn =styled(Grid.Column)`
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
`
export const ImageContainer = styled(({darkTheme, ...rest}) => (<div {...rest}/>))`
    margin-left: auto;
    margin-right: auto;
    ${mq({
        paddingBottom: ['15px','25px','40px'],
        backgroundColor: [theme.brand.colors.grey, darkTheme ? theme.brand.colors.black : theme.brand.colors.grey,'transparent']
    })}
`
export const PlateImageHeading = styled.div`
    display: block;
    clear: both;
    margin-bottom: 8px;
    ${mq({
        width:['100%','100%','80%']
    })}
`

export const PlateImageTitle = styled(H6)`
    display: inline-block;
    margin-bottom: 0;
`

export const PlateImageSize = styled(({textColor, ...rest}) => <span {...rest}/>)`
    ${mq({
        fontSize: ['10px','16px','16px'],
        lineHeight: ['24px','22px','22px']
    })}
    float: right;
    text-align: left;
    font-weight: bold;
    letter-spacing: -0.32px;
    color: ${props => props.textColor};
    padding-left: 4px;
`
export const PlateImageContainer = styled.div`
    ${mq({
        width:['100%','100%','80%']
    })}
    margin-bottom: 14px;
    margin-top:16px;
`
export const PlateImage = styled(({...others})=><Image {...others} />)`
    &&&&{
        object-fit: cover;
        width: ${props=>props.width};
        height: 100%;
    }  
`

export const InfoBannerPlateImage = styled(({width,...others})=><Image {...others} />)`
    &&&&{
        object-fit: cover;
        width: ${props=>props.width};
    }  
`
export const MobileImageContainer = styled.div`
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: 5px;
    padding-right: 5px;
`
export const MobilePlateImageHeading = styled(({textColor, ...rest})=>(<div {...rest} />))`
    display: block;
    clear: both;
    margin-bottom: 8px;
    margin-top: 20px;

    &:first-of-type {
        margin-top: 0;
    }
    color:${props => props.textColor};
`
export const MobilePlateImageContainer = styled.div`
`
export const MobilePreviewContainer = styled(Container)`
    padding-bottom: 60px;
`
export const MobileStyleHeadingAddToCart =styled(H3)`
    ${mq({
        paddingTop: ["10px","20px","0"]
    })}
    margin-bottom: 0;    
    color: white;
`

export const MobileStyleHeading =styled(H3)`
    ${mq({
        paddingTop: ["0px","20px","0"]
    })}
    margin-bottom: 0;
`
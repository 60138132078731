import React, { FunctionComponent } from 'react'
import { Icon } from 'semantic-ui-react'
import { isMobile as isMobileBrowser } from "@utils/Helpers";

import {
    ProgressStatusBarSegment,
    GridRowBar,
    ProgressBarContainer,
    ProgressBarGrid,
    GridColumnItem,
    ProgressBarStrip,
    ProgressBarStatus,
    ListItemStep,
    MobileProgressList,
    MobileProgressTitle
} from './ProgressStatusBar.styles'

type Props = {
    currentStep: number,
    isMobile: boolean
}

const ProgressStatusBar: FunctionComponent<Props> = ({currentStep, isMobile}) =>{
    const percent = currentStep * 20;
    return <ProgressStatusBarSegment basic>
        {/* {!isMobileBrowser() &&
        <ProgressBarContainer>
            {
                isMobile ?

                <MobileProgressList horizontal>
                    <MobileProgressStep title="Choose Combination" currentStep={currentStep} progressStep={1} />
                    <MobileProgressStep title="Select a Style" currentStep={currentStep} progressStep={2} />
                    <MobileProgressStep title="Personal Details" currentStep={currentStep} progressStep={3} />
                    <MobileProgressStep title="Review & payment" currentStep={currentStep} progressStep={4} />
                </MobileProgressList>
                :
                <ProgressBarGrid columns={4}>
                    <GridRowBar>
                        <ProgressStep title="Choose Combination" currentStep={currentStep} progressStep={1} />
                        <ProgressStep title="Select a Style" currentStep={currentStep} progressStep={2} />
                        <ProgressStep title="Personal Details" currentStep={currentStep} progressStep={3} />
                        <ProgressStep title="Review & payment" currentStep={currentStep} progressStep={4} />
                    </GridRowBar>
                </ProgressBarGrid>
            }

        </ProgressBarContainer>} */}
        {typeof document != "undefined" &&
        <ProgressBarStrip url={document.location.href}>
            <ProgressBarStatus progress={percent} url={document.location.href} ></ProgressBarStatus>
        </ProgressBarStrip>}
    </ProgressStatusBarSegment>
}

interface ProgressStepProps{
    title: string
    currentStep: number
    progressStep: number
}

const ProgressStep:FunctionComponent<ProgressStepProps> = ({title, currentStep, progressStep}) =>{
    const iconColor = progressStep === currentStep ? "grey":  progressStep < currentStep ? "green": "grey";
    return <GridColumnItem>
            <Icon name="check circle" color={iconColor}/>{title}
        </GridColumnItem>
}

const MobileProgressStep:FunctionComponent<ProgressStepProps> = ({title, currentStep, progressStep}) =>{
    const isActiveStep = progressStep === currentStep;
    const iconColor: "grey" | "green" | "grey" = progressStep === currentStep ? "grey":  progressStep < currentStep ? "green": "grey";

    return <ListItemStep isActive={isActiveStep}>
            <Icon name="check circle" className={iconColor}/>
            {
                isActiveStep && <MobileProgressTitle>{title}</MobileProgressTitle>
            }
        </ListItemStep>
}
export default ProgressStatusBar;
import React, { FunctionComponent } from 'react';

interface Props{
  fill?: string,
  width?: number
  height?: number
}

const Instagram: FunctionComponent<Props> = (props) =>{
  const width = '' + props.width;
  const height = '' + props.height;
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 30">
        <path data-name="Path 20" d="M35.289,23.88H26.347a2.591,2.591,0,0,0-2.588,2.588V35.41A2.592,2.592,0,0,0,26.347,38h8.941a2.592,2.592,0,0,0,2.589-2.589V26.468A2.591,2.591,0,0,0,35.289,23.88ZM30.818,35.587a4.648,4.648,0,1,1,4.648-4.648A4.653,4.653,0,0,1,30.818,35.587Zm4.8-8.334a1.1,1.1,0,1,1,1.1-1.1A1.1,1.1,0,0,1,35.615,27.252Z" transform="translate(-15.818 -15.899)" fill={props.fill}/>
        <path data-name="Path 21" d="M39.533,36.971a2.684,2.684,0,1,0,2.684,2.683A2.687,2.687,0,0,0,39.533,36.971Z" transform="translate(-24.533 -24.614)" fill={props.fill}/>
        <path data-name="Path 22" d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm9.023,19.511a4.557,4.557,0,0,1-4.552,4.552H10.529a4.557,4.557,0,0,1-4.552-4.552V10.57a4.557,4.557,0,0,1,4.552-4.552h8.941a4.557,4.557,0,0,1,4.552,4.552Z" fill={props.fill}/>
      </svg>

    )
}

Instagram.defaultProps = {
  fill: "#fff",
  width: 30,
  height: 30
}
export default Instagram
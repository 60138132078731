import React from 'react'
import styled from '@emotion/styled'
import mq from '@styles/mq'
import {Segment, Image,Icon} from 'semantic-ui-react'
import {Caption, H2} from '@styles/Global.styles';
import theme from '@styles/theme'

export const CustomerReviewCardSliderSegment = styled(({backgroundColor, ...rest})=><Segment {...rest} />)`
  &&& {
    border: none;
    border-radius: 0;
    margin: 0;
    ${mq({
      padding:[ '40px 0px 0px 0px','48px 0 24px 0','48px 0 24px 0']
    })}
    background-color: ${props => props.backgroundColor}; 
    box-shadow: none;
  }
`;

export const CustomerReviewCardSliderHeader = styled(H2)`
  margin-top:10px;
  > p > span {
      color: #3DAE2B;
  }
  ${mq({
    textAlign:['center','left','left'],
    marginLeft:['','15px','15px'],
    fontSize:['24px','30px','30px']
})}

`
export const RatingStars = styled.div`
  display: inline;
  vertical-align: top;
  padding-left: 6px;
`
export const GoogleLogoIcon = styled.div`
  padding-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const RatingMessage = styled.div`
  color: #949494;
  font-size: 14px;
  font-weight: light;
`
export const GoogleLogoWrapper = styled.a`
`
export const GoogelReviewSummary = styled.div`
  display: flex;
  background-color: white;
  margin: auto;
  ${mq({
    marginRight:['auto','15px','15px']
})}
  
  width: 242px;
  height: 90px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 16px #0000001F;
  border-radius: 12px;
`
export const RatingNumber = styled.span`
  font-size:20px;
  font-weight:bold;
`
export const CardContainer = styled.div`
    background-color: white;
    box-shadow: 0px 6px 16px #0000001F;
    border-radius: 12px;
    margin-top: 30px;
    margin-right:15px;
    margin-left: 15px;
    margin-bottom: 40px;    
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${mq({
      padding:[ '24px 24px 24px 24px','30px 30px 30px 30px']
    })}
`
export const CardIconContainer = styled.div`
  position: relative;
  img {
    position: absolute !important;
    bottom: 0;
  }
  i {
    color: ${theme.brand.colors.green}
  }
` 

export const AvatarImage = styled(Image)`
    &&&&{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }  
`

export const CardUserRatingContainer = styled.div`
  height: 20px;
  float: left;
  vertical-align:middle;
`
export const CardUser = styled.div`
  padding-top: 3px;
  padding-bottom: 4px;
`

export const CardRating = styled.div`
    float: left;
`
export const CardTitle = styled.div`
  clear: both;
  padding-top: 24px;
`

export const ReviewContent = styled(Caption)`
  font-weight: 500;
  font-size: 16px;
  color: #707070;
  width: 100%;
  ${mq({
      lineHeight:['20px','24px'],
      paddingTop:['0px','20px'],
      minHeight: ['120px','140px']
  })}
`

export const AvatarIconContainer = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  background-color: ${theme.brand.colors.purple};
  border-radius: 25px;
  padding-left: 7px;
  padding-top: 7px;
  img {
    position: absolute !important;
    bottom: 0;
  }
` 

export const AvatarIcon = styled(Icon)`
  width: 36px;
  height: 36px;
  color: #FFFFFF;
  margin-right: 0 !important;
`

export const CustomerReviewSliderArrowContainer = styled(({arrowSide, ...others})=><div {...others} />)`
    &&&& {
        ${props => props.arrowSide}: -90px;
        border: 3px solid ${theme.brand.colors.darkGrey} !important;
        width: 60px !important;;
        height: 60px !important;;
        border-radius: 60px; 
        font-size: 0; 
        line-height: 0;
        position: absolute;
        top: 42%;
        width: 20px;
        height: 20px;
        padding: 14px ${props => props.arrowSide === 'left' ? '10px' : '14px'};
        cursor: pointer;
        border: none; 

        i {
            color: ${theme.brand.colors.darkGrey} !important;
        }
    } 
`

export const CustomerReviewSubtitle =styled.div`
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  ${mq({
    textAlign:['center','left','left'],
    marginLeft:['','15px','15px'],
    fontSize:['14px', '16px', '16px']
})}
`

export const CardDate = styled.div`
 float:right;
 font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: #707070;
`